import axios from './axios'

const api = {
    categoryList({params, callback}) {
        let url = `/oa/transaction-category/list?transactionType=${params.type}`
        if (params.sortColumn)
            url += `&sortColumn=${params.sortColumn}`
        if (params.sortRule)
            url += `&sortRule=${params.sortRule}`
        axios.get(url).then(res => {
            callback(res)
        }).catch(err => {
            callback(err)
        })
    },
    categoryAdd({params, callback}) {
        axios.post(`/oa/transaction-category/add`, params).then(res => {
            callback(res)
        }).catch(err => {
            callback(err)
        })
    },
    categoryUpdate({params, callback}) {
        axios.put(`/oa/transaction-category/update`, params).then(res => {
            callback(res)
        }).catch(err => {
            callback(err)
        })
    },
    deleteCategory({params, callback}) {
        axios.delete(`/oa/transaction-category/delete/${params}`).then(res => {
            callback(res)
        }).catch(err => {
            callback(err)
        })
    },
    payeeOfCategory({params, callback}) {
        axios.get(`/oa/transaction-category/payee-of-category/${params.categoryId}?pageNum=${params.pageNum}&pageSize=${params.pageSize}&type=${params.type}`).then(res => {
            callback(res)
        }).catch(err => {
            callback(err)
        })
    },
    moveCategory({params, callback}) {
        axios.post(`/oa/transaction-category/move-category`, params).then(res => {
            callback(res)
        }).catch(err => {
            callback(err)
        })
    },

}

export default api