import { useHistory, useLocation } from "react-router-dom";
import { useEffect }from 'react'
import api from '../../api/login'
import '../../assets/css/nav.scss'

const list = [
    {
        key: 0,
        path: '/statistics',
        name: 'Statistics'
    },
    {
        key: 1,
        path: '/customer',
        name: 'Customer'
    },
    {
        key: 2,
        path: '/category',
        name: 'Category',
        childpath: ['/payee', '/description']
    },
    {
        key: 3,
        path: '/version',
        name: 'Version'
    }

]
const Nav = (props) => {

    useEffect(() => {
        if (!localStorage.getItem('token')) {
            history.push('/login')
        } else if (location.pathname === '/') {
            history.push('/statistics')
        }
    }, [])



    const history = useHistory()

    const location = useLocation()    

    const logout = () => {
        localStorage.clear()
        history.push('/login')
        api.signOut({
            callback: (res) => {
                // 
            }
        })
    }

    return(
        <div className="nav-wrp clear">
            <div className="logo"></div>
            <ul>
                {list.map(item => {
                    return (
                    <li onClick={() => {history.push(item.path)}} key={item.key}
                    className={location.pathname === item.path || (item.childpath && item.childpath.indexOf(location.pathname.split('?')[0]) !== -1)  ? 'active' : ''}>{item.name}</li>
                    )
                })}
            </ul>
            <div className="out" onClick={() => {logout()}}>Log out</div>
        </div>
    )
}

export default Nav