import axios from 'axios';
import { message } from 'antd'

// const baseURL = 'http://52.62.74.246:8080'

const baseURL = 'https://admin-api.moneyvase.com'

// const baseURL = 'https://admin-api.moneyvase.com'
// 创建axios实例
let service = axios.create({
    timeout: 60000, // 请求超时时间
    responseType: 'json',
    headers: {
        "Content-Type": "application/json",
        "Accept-Language": "en-US"
    }
})


// request拦截器：对请求参数做监听和处理
service.interceptors.request.use(
    (config) => {
        config.headers['moneyvaseoa-satoken'] = localStorage.getItem('token')
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

// response拦截器：对相应数据做处理
service.interceptors.response.use(
    (response) => {
        return response
    },
    (error) => {
        return Promise.reject(error)
    }
)

const requestMethod = ['get', 'delete', 'post', 'put', 'patch']

const $axios = {}


requestMethod.forEach(method => {
    $axios[method] = function(url, data = {}, cancelToken) {
        return new Promise((resolve, reject) => {
            if (method === 'get') {
                service[method](`${baseURL}${url}`, {cache: false})
                .then(response => {
                    packageRespon(response, resolve);
                }, err => {
                    packageError(err)
                })
                .catch((error) => {
                    reject(error)
                    packageCatch(error)
                })
            } else {
                service[method](baseURL + url, data, {cancelToken: cancelToken})
                .then(response => {
                    packageRespon(response, resolve);
                }, err => {
                    packageError(err, resolve)
                })
                .catch((error) => {
                    reject(error)
                    packageCatch(error, resolve)
                })
            }
        })
    }
})

function packageRespon(res, resolve) {
    if (res.status === 200) {
        // console.log(res.data.code)
        //if (res.data.code === 50001) {
            // Jump('/login')
        //}
        // else
            resolve(res);
    } else {
        // message.error(res.data.message);
    }
}


function packageError(err, resolve) {
    if (err !== 'Operationcanceled' && err.message !== 'Operationcanceled') {
        if (err === 'Error: timeout of 60000ms exceeded') {
            message.error('Unable to connect to the server.');
        } else if (err === 'Error: Network Error' || err.message === 'Network Error'){
            message.error('Unable to connect to the server.')
        } else {
            // message.error(err);
        }
        resolve(err)
    }
}


function packageCatch(err) {
    // message.error(err.message);
}


export default $axios
