import { useState, useEffect } from 'react'
import Nav from '../../common/nav'
import {HashRouter as Router, Route, Switch} from 'react-router-dom'
  
const Main = (props) => {

    return(
        <Router>
            <div className="main-content">
                <Nav parentProps={props} />
                <div className="btm"><Switch>
                    {props.routes.map((route, index) => {
                        return (
                            <Route path={route.path} key={index} props={props}
                            render = {props => (
                                <route.component {...props} />
                            )}
                            />
                        )
                    })}
                </Switch></div>
            </div>
        </Router>
    )

}

export default Main