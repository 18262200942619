import { useState, useEffect, useRef }from 'react';
import ReactCardFlip from "react-card-flip";
import { message, Form, Input, Button, Modal } from 'antd';
import api from '../../../api/obliterate';
import '../../../assets/css/delete.scss';
import { LoadingOutlined } from '@ant-design/icons';

const DeleteUser = (props) => {

    const [isPc, setIsPc] = useState(0); // 0 phone, 1 pc, 2 dont know yet

    useEffect(() => {
        let u = navigator.userAgent;
        if (!!u.match(/compatible/i) || u.match(/Windows/i)) {
          setIsPc(1);
        } else if (!!u.match(/Macintosh/i) || u.match(/MacIntel/i)) {
          setIsPc(1);
        } else if (!!u.match(/iphone/i) || u.match(/Ipad/i)) {
          setIsPc(0);
        } else if (!!u.match(/android/i)) {
          setIsPc(0);
        } else {
          setIsPc(1);
        }
    }, []);

    const [transform, setTransform] = useState(0);

    const [isFlipped, setIsFlipped] = useState(false);

    const toCommence = () => {
        setIsFlipped(true);
    }

    const [formRef] = Form.useForm();

    const [loading, setLoading] = useState(false);

    const [mobile, setMobile] = useState('');

    const [digits, setDigits] = useState(0);

    // 获取密码位数
    const onFinish = (values) => {
        // setIsFlipped(true);
        if (loading)
            return;
        if (loading) return;
        setLoading(true)
        api.checkApi({
            params: {
                areaCode: "+61",
                userMobile: `4${values.mobile.replace(/[^\d]/g, "")}`
            },
            callback: (res) => {
                setLoading(false)
                if (res.data.code === 200) {
                    setMobile(`4${values.mobile.replace(/[^\d]/g, "")}`)
                    setTransform(1);
                    setIsFlipped(false);
                    setDigits(res.data.data.length)
                } else {
                    message.error(res.data.message)
                }
            }
        })
    };

    const onFinishFailed = () => {
        //
    }

    const numberOnly = (e) => {
        let temp = e.replace(/[^\d]/g, "");
        formRef.setFieldsValue({
          mobile: temp.slice(0, 8),
        });
    };

    const blurEvt = (e) => {
        if (!e)
            return
        let temp = e.replace(/[^\d]/g, "");
        if (temp.length !== 8)
            return
        formRef.setFieldsValue({
            mobile: `${e.slice(0, 2)} ${e.slice(2, 5)} ${e.slice(5)}`,
        });
    }

    const focusEvt = (e) => {
        if (!e)
            return
        let temp = e.replace(/[^\d]/g, "");
        formRef.setFieldsValue({
            mobile: temp,
        });
    }

    ////////////////////////////////////////////////////////////////////////////////////////
    const [formRefCode] = Form.useForm();

    let inputEl1 = useRef();
    let inputEl2 = useRef();
    let inputEl3 = useRef();
    let inputEl4 = useRef();
    let inputEl5 = useRef();
    let inputEl6 = useRef();

    const codeChange = (current, value) => {
        let temp = value.replace(/[^\d]/g, "");
        if (current === "input1" && temp.length === 1) {
          inputEl2.current.focus();
          formRefCode.setFieldsValue({
            input1: temp,
          });
        } else if (current === "input1" && temp.length === 0) {
          formRefCode.setFieldsValue({
            input1: temp,
          });
        } else if (current === "input2" && value.length === 1) {
          inputEl3.current.focus();
          formRefCode.setFieldsValue({
            input2: temp,
          });
        } else if (current === "input2" && value.length === 0) {
          inputEl1.current.focus();
          formRefCode.setFieldsValue({
            input2: temp,
          });
        } else if (current === "input3" && value.length === 1) {
          inputEl4.current.focus();
          formRefCode.setFieldsValue({
            input3: temp,
          });
        } else if (current === "input3" && value.length === 0) {
          inputEl2.current.focus();
          formRefCode.setFieldsValue({
            input3: temp,
          });
        } else if (current === "input4" && value.length === 0) {
          inputEl3.current.focus();
          formRefCode.setFieldsValue({
            input4: temp,
          });
        } else if (current === "input4" && value.length === 1) {
          if (digits === 6)
            inputEl5.current.focus();
          formRefCode.setFieldsValue({
            input4: temp,
          });
        } else if (current === "input5" && value.length === 0) {
          inputEl4.current.focus();
          formRefCode.setFieldsValue({
            input5: temp,
          });
        } else if (current === "input5" && value.length === 1) {
          inputEl6.current.focus();
          formRefCode.setFieldsValue({
            input5: temp,
          });
        } else if (current === "input6" && value.length === 0) {
            inputEl5.current.focus();
            formRefCode.setFieldsValue({
              input6: temp,
            });
          } else if (current === "input6" && value.length === 1) {
            formRefCode.setFieldsValue({
              input6: temp,
            });
          }
    };

    const [verifying, setVerifying] = useState(false);

    const [password, setPassword] = useState('');

    // 登录
    const onFinishCode = (values) => {
        if (verifying) return;
        setVerifying(true);
        let code = '';
        if (digits === 6)
            code = `${values.input1}${values.input2}${values.input3}${values.input4}${values.input5}${values.input6}`;
        if (digits === 4)
            code = `${values.input1}${values.input2}${values.input3}${values.input4}`;

        api.getKeyApi({
            callback: (res) => {
                if (!res || !res.data) {
                    setVerifying(false);
                    return;
                }
                if (res.data.code === 200) {
                    toLogin(code, res.data.data)
                } else {
                    setVerifying(false);
                    message.error(res.data.message);
                }
            },
        });
    };

    const toLogin = (c, k) => {
        api.loginApi({
            params: {
                areaCode: "+61",
                // code: code,
                password: c,
                userMobile: mobile.replace(/[^\d]/g, "")
            },
            key: k,
            callback: (res) => {
                if (!res) {
                    setVerifying(false);
                    return;
                }
                if (res.data.code === 200) {
                    localStorage.setItem('token', res.headers['moneyvaseoa-satoken'])
                    setPassword(c)
                    sendCode()
                } else {
                    setVerifying(false);
                    message.error(res.data.message);
                }
            },
        });
    }
    
    // 发送验证码
    const [sending, setSending] = useState(false);

    const sendCode = () => {
        if (sending || time !== 0)
            return
        setSending(true);
        api.sendApi({
            callback: (res) => {
                setVerifying(false);
                setSending(false);
                if (!res) return;
                if (res.data.code === 200) {
                    setIsFlipped(true)
                    console.log(transform)
                    setTime(59);
                    let timpstamp = Math.floor(new Date().getTime() / 1000);
                    localStorage.setItem("time", JSON.stringify(timpstamp));
                } else {
                    message.error(res.data.message);
                }
            },
        })
    }

    const onFinishFailedCode = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    ////////////////////////////////////////////////////////////////////////////////////////
    const [formRefPhoneCode] = Form.useForm();

    let code1 = useRef();
    let code2 = useRef();
    let code3 = useRef();
    let code4 = useRef();

    const phoneCdeChange = (current, value) => {
        let temp = value.replace(/[^\d]/g, "");
        if (current === "code1" && temp.length === 1) {
          code2.current.focus();
          formRefPhoneCode.setFieldsValue({
            code1: temp,
          });
        } else if (current === "code1" && temp.length === 0) {
            formRefPhoneCode.setFieldsValue({
            code1: temp,
          });
        } else if (current === "code2" && value.length === 1) {
          code3.current.focus();
          formRefPhoneCode.setFieldsValue({
            code2: temp,
          });
        } else if (current === "code2" && value.length === 0) {
          code1.current.focus();
          formRefPhoneCode.setFieldsValue({
            code2: temp,
          });
        } else if (current === "code3" && value.length === 1) {
          code4.current.focus();
          formRefPhoneCode.setFieldsValue({
            code3: temp,
          });
        } else if (current === "code3" && value.length === 0) {
          code2.current.focus();
          formRefPhoneCode.setFieldsValue({
            code3: temp,
          });
        } else if (current === "code4" && value.length === 0) {
          code3.current.focus();
          formRefPhoneCode.setFieldsValue({
            code4: temp,
          });
        } else if (current === "code4" && value.length === 1) {
          formRefCode.setFieldsValue({
            code4: temp,
          });
        }
    };

    // 校验验证码
    const [phoneCode, setPhoneCode] = useState('');
    const onFinishPhoneCode = (values) => {
        if (verifying) return;
        setVerifying(true);
        let code = `${values.code1}${values.code2}${values.code3}${values.code4}`;
        setPhoneCode(code)
        api.checkCodeApi({
            params: {
                code: code,
            },
            callback: (res) => {
                setVerifying(false);
                if (!res) return;
                if (res.data.code === 200) {
                    setTransform(2);
                    setIsFlipped(false)
                } else {
                    message.error(res.data.message);
                }
            },
        });
    };

    const onFinishFailedPhoneCode = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    const localTimeInit = localStorage.getItem("time")
    ? localStorage.getItem("time")
    : "0";
    const currentTimeInit = Math.floor(new Date().getTime() / 1000);
    const [time, setTime] = useState(
        localTimeInit && localTimeInit !== "0"
        ? currentTimeInit - JSON.parse(localTimeInit) > 60
            ? 0
            : 60 - (currentTimeInit - JSON.parse(localTimeInit))
        : 0
    ); //倒计时时间

    useEffect(() => {
        //如果设置倒计时且倒计时不为0
        if (time && time !== 0)
          timeRef.current = setTimeout(() => {
            setTime((time) => time - 1);
          }, 1000);
        //清楚延时器
        return () => {
          clearTimeout(timeRef.current);
        };
    }, [time]);

    const timeRef = useRef(); //设置延时器

    // 重新发送
    const resend = () => {
        formRefPhoneCode.setFieldsValue({
            code1: '',
            code2: '',
            code3: '',
            code4: '',
        });
        
        formRefPhoneCode.resetFields(["code1", "code2", "code3", "code4"]);

        formRefCode.setFieldsValue({
            input1: '',
            input2: '',
            input3: '',
            input4: '',
            input5: '',
            input6: '',
        });
        formRefCode.resetFields(["input1", "input2", "input3", "input4", "input5", "input6"]);

        sendCode()
    };

    /* 删除 */
    const [isModalVisible, setIsModalVisible] = useState(false);

    const [deleting, setDeleting] = useState(false)

    const handleOk = () => {
        if (deleting)
            return
        setDeleting(true)
        formRef.setFieldsValue({
            mobile: "",
        });
        formRef.resetFields(["mobile"]);
        formRefPhoneCode.setFieldsValue({
            code1: '',
            code2: '',
            code3: '',
            code4: '',
        });
        
        formRefPhoneCode.resetFields(["code1", "code2", "code3", "code4"]);

        formRefCode.setFieldsValue({
            input1: '',
            input2: '',
            input3: '',
            input4: '',
            input5: '',
            input6: '',
        });
        formRefCode.resetFields(["input1", "input2", "input3", "input4", "input5", "input6"]);
        api.deleteApi({
            params: {
                code: phoneCode
            },
            callback: (res) => {
                setDeleting(false)
                setIsModalVisible(false)
                if (!res.data)
                    return
                if (res.data.code === 200) {
                    message.success('Successfully deleted');
                    setTransform(0);
                    localStorage.clear();
                } else {
                    message.error(res.data.message);
                }
            }
        })
    }

    const handleCancel = () => {
        if (deleting)
            return
        setIsModalVisible(false)
    }

    return(
        <div className="delete-wrp">
            <div className="logo"></div>
            <div className="container">
                <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
                    <div className="front" style={{ opacity: !isFlipped ? 1 : 0 }}>
                        {transform === 0 ? <div className="box">
                            <h1 className="warn">Delete moneyvase's account</h1>
                            <p>You can delete your moneyvase APP account from the web</p>
                            <Button className="delete-btn" onClick={() => {
                                toCommence()
                            }}>Delete account</Button>
                        </div> : ''}

                        {transform === 1 ? <div className="box">
                            <h1>Login to moneyvase</h1>
                            <div className="line"></div>
                            <h2>Please Enter {digits} Digit Passcode</h2>
                            <Form
                            className="codes-form"
                            layout="vertical"
                            form={formRefCode}
                            onFinish={onFinishCode}
                            onFinishFailed={onFinishFailedCode}
                            >
                                <div className={digits === 4 ? "codes-input-four clear" : "codes-input clear"}>
                                    <div className="customize-input">
                                        <Form.Item
                                        label=" "
                                        name="input1"
                                        validateTrigger={["onBlur"]}
                                        rules={[
                                            {
                                            required: true,
                                            message: " ",
                                            },
                                        ]}
                                        >
                                        <Input
                                            className="input"
                                            maxLength={1}
                                            ref={inputEl1}
                                            onChange={(e) => {
                                            codeChange("input1", e.target.value);
                                            }}
                                        />
                                        </Form.Item>
                                    </div>
                                    <div className="customize-input">
                                        <Form.Item
                                        label=" "
                                        name="input2"
                                        validateTrigger={["onBlur"]}
                                        rules={[
                                            {
                                            required: true,
                                            message: " ",
                                            },
                                        ]}
                                        >
                                        <Input
                                            className="input"
                                            maxLength={1}
                                            ref={inputEl2}
                                            onChange={(e) => {
                                            codeChange("input2", e.target.value);
                                            }}
                                        />
                                        </Form.Item>
                                    </div>
                                    <div className="customize-input">
                                        <Form.Item
                                        label=" "
                                        name="input3"
                                        validateTrigger={["onBlur"]}
                                        rules={[
                                            {
                                            required: true,
                                            message: " ",
                                            },
                                        ]}
                                        >
                                        <Input
                                            className="input"
                                            maxLength={1}
                                            ref={inputEl3}
                                            onChange={(e) => {
                                            codeChange("input3", e.target.value);
                                            }}
                                        />
                                        </Form.Item>
                                    </div>
                                    <div className="customize-input">
                                        <Form.Item
                                        label=" "
                                        name="input4"
                                        validateTrigger={["onBlur"]}
                                        rules={[
                                            {
                                            required: true,
                                            message: " ",
                                            },
                                        ]}
                                        >
                                        <Input
                                            className="input"
                                            maxLength={1}
                                            ref={inputEl4}
                                            onChange={(e) => {
                                            codeChange("input4", e.target.value);
                                            }}
                                        />
                                        </Form.Item>
                                    </div>
                                    {digits === 6 ? <><div className="customize-input">
                                        <Form.Item
                                        label=" "
                                        name="input5"
                                        validateTrigger={["onBlur"]}
                                        rules={[
                                            {
                                            required: true,
                                            message: " ",
                                            },
                                        ]}
                                        >
                                        <Input
                                            className="input"
                                            maxLength={1}
                                            ref={inputEl5}
                                            onChange={(e) => {
                                            codeChange("input5", e.target.value);
                                            }}
                                        />
                                        </Form.Item>
                                    </div>
                                    <div className="customize-input">
                                        <Form.Item
                                        label=" "
                                        name="input6"
                                        validateTrigger={["onBlur"]}
                                        rules={[
                                            {
                                            required: true,
                                            message: " ",
                                            },
                                        ]}
                                        >
                                        <Input
                                            className="input"
                                            maxLength={1}
                                            ref={inputEl6}
                                            onChange={(e) => {
                                            codeChange("input6", e.target.value);
                                            }}
                                        />
                                        </Form.Item>
                                    </div></> : ''}
                                </div>
                                <Button className="singin-btn" htmlType="submit">Next {verifying ? <LoadingOutlined /> : ''}</Button>
                            </Form>
                        </div> : ''}

                        {transform === 2 ? <div className="box">
                            <h1 className="warn">Delete moneyvase's account</h1>
                            <p>Thank you for your using moneyvase.</p>
                            <p>Please note that once your account is deleted, you will lose all your financial and transactional data on the platform including your personal information, customisation and preference etc.</p>
                            <Button className="delete-btn" onClick={() => {
                                setIsModalVisible(true)
                            }}>Delete account</Button>
                        </div> : ''}
                    </div>
                    <div className="back" style={{ opacity: isFlipped ? 1 : 0 }}>
                        {transform === 0 ? <div className="box" style={{'padding': '40px 10px'}}>
                            <div className="shrink">
                                <h1>Login to moneyvase</h1>
                                <div className="line"></div>
                                <Form
                                className="form-wrap"
                                form={formRef}
                                layout="vertical"
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                autoComplete="off"
                                >
                                    <Form.Item
                                        label="Mobile Number"
                                        name="mobile"
                                        validateTrigger={["onBlur"]}
                                        rules={[
                                            { required: true, message: "Please enter the correct mobile number format" },
                                            {
                                            min: 8,
                                            max: 10,
                                            message: "Please enter the correct mobile number format",
                                            },
                                        ]}
                                    >
                                        <Input
                                        prefix="+614"
                                        placeholder=" "
                                        onChange={(e) => {
                                            numberOnly(e.target.value);
                                        }}
                                        onBlur={(e) => {
                                            blurEvt(e.target.value);
                                        }}
                                        onFocus={(e) => {
                                            focusEvt(e.target.value);
                                        }}
                                        />
                                    </Form.Item>
                            
                                    <Button className="singin-btn" htmlType="submit">Sign In {loading ? <LoadingOutlined /> : ''}</Button>
                            
                                </Form>
                            </div>
                        </div> : ''}
                        {transform === 1 ? <div className="box">
                            <h1>Please enter verification code</h1>
                            <div className="line"></div>
                            <h2>We have sent a 4-digit verification code to your phone.</h2>
                            <Form
                            className="codes-form"
                            layout="vertical"
                            form={formRefPhoneCode}
                            onFinish={onFinishPhoneCode}
                            onFinishFailed={onFinishFailedPhoneCode}
                            >
                                <div className="codes-input-four clear">
                                    <div className="customize-input">
                                        <Form.Item
                                        label=" "
                                        name="code1"
                                        validateTrigger={["onBlur"]}
                                        rules={[
                                            {
                                            required: true,
                                            message: " ",
                                            },
                                        ]}
                                        >
                                        <Input
                                            className="input"
                                            maxLength={1}
                                            ref={code1}
                                            onChange={(e) => {
                                                phoneCdeChange("code1", e.target.value);
                                            }}
                                        />
                                        </Form.Item>
                                    </div>
                                    <div className="customize-input">
                                        <Form.Item
                                        label=" "
                                        name="code2"
                                        validateTrigger={["onBlur"]}
                                        rules={[
                                            {
                                            required: true,
                                            message: " ",
                                            },
                                        ]}
                                        >
                                        <Input
                                            className="input"
                                            maxLength={1}
                                            ref={code2}
                                            onChange={(e) => {
                                                phoneCdeChange("code2", e.target.value);
                                            }}
                                        />
                                        </Form.Item>
                                    </div>
                                    <div className="customize-input">
                                        <Form.Item
                                        label=" "
                                        name="code3"
                                        validateTrigger={["onBlur"]}
                                        rules={[
                                            {
                                            required: true,
                                            message: " ",
                                            },
                                        ]}
                                        >
                                        <Input
                                            className="input"
                                            maxLength={1}
                                            ref={code3}
                                            onChange={(e) => {
                                                phoneCdeChange("code3", e.target.value);
                                            }}
                                        />
                                        </Form.Item>
                                    </div>
                                    <div className="customize-input">
                                        <Form.Item
                                        label=" "
                                        name="code4"
                                        validateTrigger={["onBlur"]}
                                        rules={[
                                            {
                                            required: true,
                                            message: " ",
                                            },
                                        ]}
                                        >
                                        <Input
                                            className="input"
                                            maxLength={1}
                                            ref={code4}
                                            onChange={(e) => {
                                                phoneCdeChange("code4", e.target.value);
                                            }}
                                        />
                                        </Form.Item>
                                    </div>
                                </div>
                                <Button className="singin-btn" htmlType="submit">Account Deactivation {verifying ? <LoadingOutlined /> : ''}</Button>
                                <div className="resend-btn" onClick={() => {
                                    resend();
                                }}>Resend{time !== 0 ? <span className="resend-text">{time}s</span> : ''}</div>
                            </Form>
                        </div> : ''}
                    </div> 
                </ReactCardFlip>
            </div>
            <Modal title=" " visible={isModalVisible}
                closable={false} width={540}
                className="remove-modal"
                centered={true}
                footer={null}
                onOk={handleOk} onCancel={handleCancel}>
                <h3>Delete account</h3>
                <p>Are you sure? This cannot be undone. All your financial data and transactions will be permanently DELETED.</p>
                <div className="modal-btns">
                    <Button className="cancel-btn" onClick={() => handleCancel()}>Cancel</Button>
                    <Button className="confirm-delete-btn" onClick={() => { handleOk() }}>Confirm{deleting ? <LoadingOutlined /> : ''}</Button>
                </div>
            </Modal>
        </div>
    )

}

export default DeleteUser;