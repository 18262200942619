import moment from "moment";
import { message } from 'antd'

export default {
  alert(type, msg){
        if(!msg) return
        message[type](msg)
  },
  getDayTimes (day) {
    if (day === 0) {
        return {
            start: null,
            end: null
        }
    }
    let time = new Date()
    let a_day = 86400000
    // 今天0点的时间戳
    let today_zero = time.getTime() - time.getHours() * 60 * 60 * 1000 - time.getMinutes() * 60 * 1000 - time.getSeconds() * 1000 - time.getMilliseconds()
    return {
        start: today_zero - (day - 1) * a_day,
        end: today_zero + a_day - 1
    }
  },
  formatTime (timestamp) {
      var time = new Date(timestamp * 1000)
      var y = time.getFullYear()
      var m = time.getMonth() + 1
      var d = time.getDate()
      var result = `${y}/${m < 10 ? `0${m}` : m}/${d < 10 ? `0${d}` : d}`
      return result
  },
  formatString (string) {
      let start_arr = string[0].split('/')
      let start = `${start_arr[2]}/${start_arr[1]}/${start_arr[0]}`
      let end_arr = string[1].split('/')
      let end = `${end_arr[2]}/${end_arr[1]}/${end_arr[0]}`
      return {
        start: (new Date(start)).getTime(),
        end: (new Date(end)).getTime() + 86400000 - 1
      }

  },

  formatTimeToRequest (timestamp) {
    if (timestamp === 0)
        return 0
    var time = new Date(timestamp * 1000)
    var y = time.getFullYear()
    var m = time.getMonth() + 1
    var d = time.getDate()
    var result = `${y}-${m < 10 ? `0${m}` : m}-${d < 10 ? `0${d}` : d}`
    return result
},
// 格式化时间 时分秒分开（用）
  timestampToTime(timestamp) {
      let date = new Date(timestamp*1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
      let Y = date.getFullYear() + ' ';
      let M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '/';
      let D = (date.getDate() < 10 ? '0'+(date.getDate()) : date.getDate()) + '/';
      //let H =(date.getHours() < 10 ? '0'+(date.getHours()) : date.getHours()) + ':';
      let H =(date.getHours() < 10 ? '0'+(date.getHours()) : date.getHours());
      if(H -12 <0){ 
          H = H+ ':'+ (date.getMinutes() < 10 ? '0'+(date.getMinutes()) : date.getMinutes()) + ' AM'; 
      }else if(H-12>0 && H-24<0){ 
          H = (H - 12) < 10?'0'+(H - 12)+ ':'+ (date.getMinutes() < 10 ? '0'+(date.getMinutes()) : date.getMinutes()) + ' PM':(H - 12)+ ':'+ (date.getMinutes() < 10 ? '0'+(date.getMinutes()) : date.getMinutes()) +' PM'; 
      }else if(H-24 ===0){ 
          H ='00' +':'+ (date.getMinutes() < 10 ? '0'+(date.getMinutes()) : date.getMinutes())+ ' PM'; 
      }else if(H-12===0){
          H = H+ ':'+ (date.getMinutes() < 10 ? '0'+(date.getMinutes()) : date.getMinutes()) + 'PM';
      }
      // let m = (date.getMinutes() < 10 ? '0'+(date.getMinutes()) : date.getMinutes());
      return H+' '+D+M+Y;
  },
    getLastCurrentDays(days){
        const startDate = moment().subtract(days, "days").startOf('day').unix();
        const endDate = moment().subtract(1, "days").endOf('day').unix();
        const date = [startDate,endDate]
        return date
    },
  timestampToDate(timestamp) {
      if (timestamp === 0)
          return ''
      if (typeof(timestamp) === 'string')
        timestamp = Number(timestamp)
      if (timestamp < 10000000000)
        timestamp = timestamp * 1000
      let date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
      let Y = date.getFullYear() + ' ';
      let M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1);
      if (M === '01') {
          M = 'Jan '
      }
      else if (M === '02') {
          M = 'Feb '
      }
      else if (M === '03') {
          M = 'Mar '
      }
      else if (M === '04') {
          M = 'Apr '
      }
      else if (M === '05') {
          M = 'May '
      }
      else if (M === '06') {
          M = 'June '
      }
      else if (M === '07') {
          M = 'July '
      }
      else if (M === '08') {
          M = 'Aug '
      }
      else if (M === '09') {
          M = 'Sep '
      }
      else if (M === '10' || M === 10) {
          M = 'Oct '
      }
      else if (M === '11' || M === 11) {
          M = 'Nov '
      }
      else if (M === '12' || M === 12) {
          M = 'Dec '
      }
      let D = (date.getDate() < 10 ? '0'+(date.getDate()) : date.getDate()) + ' ';
      // let m = (date.getMinutes() < 10 ? '0'+(date.getMinutes()) : date.getMinutes());
      return D+M+Y;
  },
  timestampGetHourMinute(timestamp){
      let date = new Date(timestamp);
      let H = (date.getHours() < 10 ? '0'+(date.getHours()) : date.getHours())
      let m = (date.getMinutes() < 10 ? '0'+(date.getMinutes()) : date.getMinutes())
      return H +':' + m
  },
  timestampToTimeMap(timestamp) {
      if(typeof(timestamp) === 'string')
        timestamp = Number(timestamp)
    let date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
    let Y = date.getFullYear() + '  ';
    let M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '';
    let D = (date.getDate() < 10 ? '0'+(date.getDate()) : date.getDate()) + '';
    //let H =(date.getHours() < 10 ? '0'+(date.getHours()) : date.getHours()) + ':';
    let H =(date.getHours() < 10 ? '0'+(date.getHours()) : date.getHours());
    if(H -12 <0){
        H = H+ ':'+ (date.getMinutes() < 10 ? '0'+(date.getMinutes()) : date.getMinutes()) + ' AM';
    }else if(H-12>0 && H-24<0){
        H = (H - 12) < 10?'0'+(H - 12)+ ':'+ (date.getMinutes() < 10 ? '0'+(date.getMinutes()) : date.getMinutes()) + ' PM':(H - 12)+ ':'+ (date.getMinutes() < 10 ? '0'+(date.getMinutes()) : date.getMinutes()) +' PM';
    }else if(H-24 ==0){
        H ='00' +':'+ (date.getMinutes() < 10 ? '0'+(date.getMinutes()) : date.getMinutes())+ ' PM';
    }else if(H-12==0){
        H = H+ ':'+ (date.getMinutes() < 10 ? '0'+(date.getMinutes()) : date.getMinutes()) + ' PM';
    }
    if (M === '01') {
        M = 'Jan '
    }
    else if (M === '02') {
        M = 'Feb '
    }
    else if (M === '03') {
        M = 'Mar '
    }
    else if (M === '04') {
        M = 'Apr '
    }
    else if (M === '05') {
        M = 'May '
    }
    else if (M === '06') {
        M = 'June '
    }
    else if (M === '07') {
        M = 'July '
    }
    else if (M === '08') {
        M = 'Aug '
    }
    else if (M === '09') {
        M = 'Sep '
    }
    else if (M === '10' || M === 10) {
        M = 'Oct '
    }
    else if (M === '11' || M === 11) {
        M = 'Nov '
    }
    else if (M === '12' || M === 12) {
        M = 'Dec '
    }
    return H+', '+ D + ' ' + M;
  },
  minuteFormat(timestamp){
      let hour = Math.floor(timestamp / 60)
      let minute = timestamp - (hour * 60)
      return `${hour}:${minute}`
  },
  timestampFormatToSend(timestamp) {
    let date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
    let Y = date.getFullYear() + '-';
    let M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
    let D = (date.getDate() < 10 ? '0'+(date.getDate()) : date.getDate()) + ' ';
    //let H =(date.getHours() < 10 ? '0'+(date.getHours()) : date.getHours()) + ':';
    let H =(date.getHours() < 10 ? '0'+(date.getHours()) : date.getHours()) + ':';
    let m = (date.getMinutes() < 10 ? '0'+(date.getMinutes()) : date.getMinutes()) + ':';
    let s = (date.getSeconds() < 10 ? '0'+(date.getSeconds()) : date.getSeconds())
    return Y + M + D + H + m + s;
  },
  timestampFormatZone(timestamp) {
    let date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
    let Y = date.getFullYear() + ' | ';
    let M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1);
    if (M === '01') {
        M = 'Jan '
    }
    else if (M === '02') {
        M = 'Feb '
    }
    else if (M === '03') {
        M = 'Mar '
    }
    else if (M === '04') {
        M = 'Apr '
    }
    else if (M === '05') {
        M = 'May '
    }
    else if (M === '06') {
        M = 'June '
    }
    else if (M === '07') {
        M = 'July '
    }
    else if (M === '08') {
        M = 'Aug '
    }
    else if (M === '09') {
        M = 'Sep '
    }
    else if (M === '10' || M === 10) {
        M = 'Oct '
    }
    else if (M === '11' || M === 11) {
        M = 'Nov '
    }
    else if (M === '12' || M === 12) {
        M = 'Dec '
    }

    let D = (date.getDate() < 10 ? '0'+(date.getDate()) : date.getDate()) + ' ';
    //let H =(date.getHours() < 10 ? '0'+(date.getHours()) : date.getHours()) + ':';
    let H =(date.getHours() < 10 ? '0'+(date.getHours()) : date.getHours()) + ':';
    let m = (date.getMinutes() < 10 ? '0'+(date.getMinutes()) : date.getMinutes());
    return M + D + Y + H + m;
  },
  timestampFormatZoneToday(timestamp) {
    let date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
    let H =(date.getHours() < 10 ? '0'+(date.getHours()) : date.getHours()) + ':';
    let m = (date.getMinutes() < 10 ? '0'+(date.getMinutes()) : date.getMinutes());
    return 'Today ' + H + m;
  },
  timestampRangeFormatToSend(timestamp, timestamp2) {
    if (typeof(timestamp) === 'string')
      timestamp = Number(timestamp)
    if (typeof(timestamp2) === 'string')
      timestamp2 = Number(timestamp2)
    let date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
    let H =(date.getHours() < 10 ? '0'+(date.getHours()) : date.getHours()) + ':';
    let m = (date.getMinutes() < 10 ? '0'+(date.getMinutes()) : date.getMinutes()) + ':';

    let date2 = new Date(timestamp2);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
    let H2 =(date2.getHours() < 10 ? '0'+(date2.getHours()) : date2.getHours()) + ':';
    let m2 = (date2.getMinutes() < 10 ? '0'+(date2.getMinutes()) : date2.getMinutes());

    return H + m + ' - ' + H2 + m2;
  },
  timestampFormatZoneTwo(timestamp, timestamp2) {
    let date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
    let Y = date.getFullYear() + ' | ';
    let M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1);
    if (M === '01') {
        M = 'Jan '
    }
    else if (M === '02') {
        M = 'Feb '
    }
    else if (M === '03') {
        M = 'Mar '
    }
    else if (M === '04') {
        M = 'Apr '
    }
    else if (M === '05') {
        M = 'May '
    }
    else if (M === '06') {
        M = 'June '
    }
    else if (M === '07') {
        M = 'July '
    }
    else if (M === '08') {
        M = 'Aug '
    }
    else if (M === '09') {
        M = 'Sep '
    }
    else if (M === '10' || M === 10) {
        M = 'Oct '
    }
    else if (M === '11' || M === 11) {
        M = 'Nov '
    }
    else if (M === '12' || M === 12) {
        M = 'Dec '
    }

    let D = (date.getDate() < 10 ? '0'+(date.getDate()) : date.getDate()) + ' ';
    let H =(date.getHours() < 10 ? '0'+(date.getHours()) : date.getHours());
    if(H -12 <0){ 
        H = H+ ':'+ (date.getMinutes() < 10 ? '0'+(date.getMinutes()) : date.getMinutes()) + ' AM'; 
    }else if(H-12>0 && H-24<0){ 
        H = (H - 12) < 10?'0'+(H - 12)+ ':'+ (date.getMinutes() < 10 ? '0'+(date.getMinutes()) : date.getMinutes()) + ' PM':(H - 12)+ ':'+ (date.getMinutes() < 10 ? '0'+(date.getMinutes()) : date.getMinutes()) +' PM'; 
    }else if(H-24 ===0){ 
        H ='00' +':'+ (date.getMinutes() < 10 ? '0'+(date.getMinutes()) : date.getMinutes())+ ' PM'; 
    }else if(H-12===0){
        H = H+ ':'+ (date.getMinutes() < 10 ? '0'+(date.getMinutes()) : date.getMinutes()) + ' PM';
    }
    
    let date2 = new Date(timestamp2);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
    let Y2 = date2.getFullYear() + ' | ';
    let M2 = (date2.getMonth()+1 < 10 ? '0'+(date2.getMonth()+1) : date2.getMonth()+1);
    if (M2 === '01') {
        M2 = 'Jan '
    }
    else if (M2 === '02') {
        M2 = 'Feb '
    }
    else if (M2 === '03') {
        M2 = 'Mar '
    }
    else if (M2 === '04') {
        M2 = 'Apr '
    }
    else if (M2 === '05') {
        M2 = 'May '
    }
    else if (M2 === '06') {
        M2 = 'June '
    }
    else if (M2 === '07') {
        M2 = 'July '
    }
    else if (M2 === '08') {
        M2 = 'Aug '
    }
    else if (M2 === '09') {
        M2 = 'Sep '
    }
    else if (M2 === '10') {
        M2 = 'Oct '
    }
    else if (M2 === '11') {
        M2 = 'Nov '
    }
    else if (M2 === '12') {
        M2 = 'Dec '
    }

    let D2 = (date2.getDate() < 10 ? '0'+(date2.getDate()) : date2.getDate()) + ' ';
    let H2 =(date2.getHours() < 10 ? '0'+(date2.getHours()) : date2.getHours());
    if(H2 -12 <0){ 
        H2 = H2+ ':'+ (date2.getMinutes() < 10 ? '0'+(date2.getMinutes()) : date2.getMinutes()) + ' AM'; 
    }else if(H2-12>0 && H2-24<0){ 
        H2 = (H2 - 12) < 10?'0'+(H2 - 12)+ ':'+ (date2.getMinutes() < 10 ? '0'+(date2.getMinutes()) : date2.getMinutes()) + ' PM':(H2 - 12)+ ':'+ (date2.getMinutes() < 10 ?
        '0'+(date2.getMinutes()) : date2.getMinutes()) +' PM'; 
    }else if(H2-24 ===0){ 
        H2 ='00' +':'+ (date2.getMinutes() < 10 ? '0'+(date2.getMinutes()) : date2.getMinutes())+ ' PM'; 
    }else if(H2-12===0){
        H2 = H2+ ':'+ (date2.getMinutes() < 10 ? '0'+(date2.getMinutes()) : date2.getMinutes()) + 'PM';
    }

    if (Y === Y2 && D === D2) {
        return {
            time: D + M + Y + H + ' - ' + H2,
            current: 1
        }
    } else{
        return {
            time: D + M + Y + H + ' - ' + D2 + M2 + Y2 + H2,
            current: 0
        }
    }
  },
  dateStringFormat(string) {
    let Y = string.split('-')[0]
    let M = string.split('-')[1]
    let D = string.split('-')[2]
    if (M === '01') {
        M = 'Jan '
    }
    else if (M === '02') {
        M = 'Feb '
    }
    else if (M === '03') {
        M = 'Mar '
    }
    else if (M === '04') {
        M = 'Apr '
    }
    else if (M === '05') {
        M = 'May '
    }
    else if (M === '06') {
        M = 'June '
    }
    else if (M === '07') {
        M = 'July '
    }
    else if (M === '08') {
        M = 'Aug '
    }
    else if (M === '09') {
        M = 'Sep '
    }
    else if (M === '10' || M === 10) {
        M = 'Oct '
    }
    else if (M === '11' || M === 11) {
        M = 'Nov '
    }
    else if (M === '12' || M === 12) {
        M = 'Dec '
    }
    return D + ' ' + M + ' ' + Y
  },
  formatNumber(s) {
    if (s.length == 11) {
      return s.slice(0, 3) + ' ' + s.slice(3, 7) + ' ' + s.slice(7, 11)
    } else if (s.length == 8) {
      return s.slice(0, 4) + ' ' + s.slice(4, 8)
    } else if (s.length == 9) {
      return s.slice(0, 3) + ' ' + s.slice(3, 6) + ' ' + s.slice(6, 9)
    } else if (s.length == 12) {
        return s.slice(0, 4) + ' ' + s.slice(4, 8) + ' ' + s.slice(8, 12)
    } else {
      return s
    }
  },
  dateStringTimestamp(string) {
    let Y = string.split('-')[0]
    let M = string.split('-')[1]
    let D = string.split('-')[2]
    let timestamp = (new Date(`${Y}/${M}/${D}`)).getTime()
    return timestamp
  },
  timestampToFormatTime(timestamp) {
    if (typeof(timestamp) === 'string')
      timestamp = Number(timestamp)
    if (timestamp < 10000000000)
      timestamp = timestamp * 1000 
    let date = new Date(timestamp);
    let Y = date.getFullYear() + ' ';
    let M = date.getMonth()+1
    if (M === 1) {
      M = 'January'
    } else if (M === 2) {
      M = 'February'
    } else if (M === 3) {
      M = 'March'
    } else if (M === 4) {
      M = 'April'
    } else if (M === 5) {
      M = 'May'
    } else if (M === 6) {
      M = 'June'
    } else if (M === 7) {
      M = 'July'
    } else if (M === 8) {
      M = 'August'
    } else if (M === 9) {
      M = 'September'
    } else if (M === 10) {
      M = 'October'
    } else if (M === '11' || M === 11) {
      M = 'November'
    } else if (M === '12' || M === 12) {
      M = 'December'
    }

    let D = (date.getDate() < 10 ? '0'+(date.getDate()) : date.getDate());
    //let H =(date.getHours() < 10 ? '0'+(date.getHours()) : date.getHours()) + ':';
    let H =(date.getHours() < 10 ? '0'+(date.getHours()) : date.getHours());
    if(H -12 <=0){ 
        H = H+ ':'+ (date.getMinutes() < 10 ? '0'+(date.getMinutes()) : date.getMinutes()) + ' am'; 
    }else if(H-12>0 && H-24<0){ 
        H = (H - 12) < 10?'0'+(H - 12)+ ':'+ (date.getMinutes() < 10 ? '0'+(date.getMinutes()) : date.getMinutes()) + ' pm':(H - 12)+ ':'+ (date.getMinutes() < 10 ? '0'+(date.getMinutes()) : date.getMinutes()) +' pm'; 
    }else if(H-24 ==0){ 
        H ='00' +':'+ (date.getMinutes() < 10 ? '0'+(date.getMinutes()) : date.getMinutes())+ ' pm'; 
    }
    // let m = (date.getMinutes() < 10 ? '0'+(date.getMinutes()) : date.getMinutes());
    return H+' '+ D + ' ' + M + ' ' + Y;
  },


}