import './App.scss';
import { HashRouter as Router, Route, Switch} from 'react-router-dom'
import routes from './router/index'

const App = () => {
    return (
      <div className="App">
        <Router>
          <Switch>
            {routes.map((route, index) => {
              return (
                <Route path={route.path} key={index} 
                render = {props => (
                  <route.component {...props} routes={route.routes} />
                )}
                />
              )
            })}
          </Switch>
        </Router>
      </div>
    )
}

export default App;
