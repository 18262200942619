import axios from './axios'

const api = {
    getUserList({params, callback}) {
        let url = `/oa/user/list?pageNum=${params.pageNum}&pageSize=${params.pageSize}`
        if (params.keyword)
            url += `&keyword=${params.keyword}`
        if (params.sortColumn)
            url += `&sortColumn=${params.sortColumn}`
        if (params.sortRule)
            url += `&sortRule=${params.sortRule}`
        axios.get(url).then(res => {
            callback(res)
        }).catch(err => {
            callback(err)
        })
    },
    userDisable({params, callback}) {
        axios.put(`/oa/user/disable/${params.userId}?isDisable=${params.isDisable}`).then(res => {
            callback(res)
        }).catch(err => {
            callback(err)
        })
    },

}

export default api