import axios from './axios'

const api = {
    getStatistics({params, callback}) {
        let url = `/oa/statistics`
        if (params.start)
            url += `?startTime=${params.start}&endTime=${params.end}`
        axios.get(url).then(res => {
            callback(res)
        }).catch(err => {
            callback(err)
        })
    },

}

export default api