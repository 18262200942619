import axios from './axios'

const api = {
    versionList({params, callback}) {
        axios.get(`/oa/version?pageNum=${params.pageNum}&pageSize=${params.pageSize}&deviceType=${params.deviceType}`).then(res => {
            callback(res)
        }).catch(err => {
            callback(err)
        })
    },
    versionAdd({params, callback}) {
        axios.post(`/oa/version`, params).then(res => {
            callback(res)
        }).catch(err => {
            callback(err)
        })
    },
}

export default api