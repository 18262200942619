import { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom";
import { Table, Dropdown, Menu, Drawer, Input, Form, Button, Modal, message } from 'antd'
import { LoadingOutlined, CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons'
import '../../../assets/css/category.scss'
import api from '../../../api/category'

const Category = () => {
    const history = useHistory()

    // 当前排序
    const [sort, setSort] = useState({
        field: 'trigger',
        type: 'ascend'
    })

    useEffect(() => {
        getInfo(sort.field, sort.type, outType)
    }, [])

    const [outType, setOutType] = useState('OUT')

    const toggleOut = (type, operate) => {
        setOutType(type)
        if (operate && operate === 'refresh') {
            getInfo(sort.field, sort.type, type)
        }
    }


    /******************************************** 表格1的数据 start ********************************************/

    const getList = (field, order) => {
        setSort({
            field: field,
            type: order
        })
        getInfo(field, order, outType)
    }
    // 获取表格数据
    const [loading, setLoading] = useState(false)

    const [list, setList] = useState([])


    const getInfo = (field, order, t) => {
        setLoading(true)
        setList([])
        let params = {
            type: t ? t : outType
        }

        if (field === 'type')
            params.sortColumn = 'addTransactionsOfThisType'
        if (field === 'trigger')
            params.sortColumn = 'automationTriggerAmount'
        if (order === 'descend')
            params.sortRule = 'DESC'
        if (order === 'ascend')
            params.sortRule = 'ASC'

        api.categoryList({
            params: params,
            callback: (res) => {
                setLoading(false)
                if (!res.data)
                    return
                if (res.data.code === 200) {
                    let lst = []
                    res.data.data.forEach((e, i) => {
                        let temp = e.automationTriggerAmount ? (Number(e.automationTriggerAmount) * 100).toFixed(2) : ''
                        let id_list = e.associateYodleeCategoryIds ?
                        (typeof(e.associateYodleeCategoryIds) === 'string' ? e.associateYodleeCategoryIds.split(',') :
                        e.associateYodleeCategoryIds) : []
                        lst.push({
                            key: i,
                            type: Number(e.addTransactionsOfThisType),
                            trigger: temp.split('.')[1] === '00' ? temp.split('.')[0] : temp,
                            categoryName: e.categoryName,
                            transactionType: e.transactionType,
                            id: e.categoryId,
                            id_list: id_list,
                            showIdString: id_list.join(', ')
                        })
                    })
                    setList(lst)
                } else if (res.data.code === 50000 || res.data.code === 50001 || res.data.code === 50002 || res.data.code === 50003 || res.data.code === 50004 || res.data.code === 50005) {
                    history.push('/login')
                } else {
                    message.error(res.data.message)
                }
            }
        })
    }

    /******************************************** 表格1的数据 end ********************************************/

    /* 表单弹窗 */

    const [requesting, setRequesting] = useState(false)

    const [showModal, setShowModal] = useState(false)

    // 点右上角的关闭
    const closeEvent = () => {
        if (requesting) {
            return
        }

        setShowModal(false)
    }

    const onFinish = (values) => {
        if (requesting) {
            return
        }
        let warn = false
        associateList.forEach(item => {
            if (item.warn)
                warn = true
        })
        if (warn)
            return
        setRequesting(true)
        let params = {
            transactionType: type,
            categoryName: values.name,
            automationTriggerAmount: percent ? (Number(percent) / 100) : null
        }
        if (associateList.length > 0) {
            let arr = []
            associateList.forEach((item) => {
                if (item.value.length === 4 || item.value.length === 5)
                    arr.push(item.value)
            })
            params.associateYodleeCategoryIdList = arr
        }
        if (isEdit) {
            params.categoryId = id
            api.categoryUpdate({
                params,
                callback: callback
            })

        } else {
            api.categoryAdd({
                params,
                callback: callback
            })
        }
    }

    const callback = (res) => {
        if (!res.data)
            return
        setRequesting(false)
        if (res.data.code === 200) {
            setShowModal(false)
            setOutType(type)
            getInfo(type)
        } else if (res.data.code === 50000 || res.data.code === 50001 || res.data.code === 50002 || res.data.code === 50003 || res.data.code === 50004 || res.data.code === 50005) {
            history.push('/login')
        } else {
            message.error(res.data.message)
        }

    }
    const onFinishFailed = (values) => {
    }

    // 限制只能输入纯数字
    const updateValue = (e, type) => {
        let temp = e.target.value.replace(/[^\d.]/g, '').replace(/^\./g, '').replace(/\.{2,}/g, '').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
        if (type === 'percent') {
            setPercent(temp)
        }
    }

    const [form] = Form.useForm();

    const { TextArea } = Input

    const [isEdit, setIsEdit] = useState(false)

    const [percent, setPercent] = useState('')
    const [percentWarn, setPercentWarn] = useState(false)

    const [type, setType] = useState('OUT')

    const toggle = (type, operate) => {
        setType(type)
        if (operate && operate === 'refresh') {
            getList()
        }
    }

    const add = () => {
        setIsEdit(false)
        setShowModal(true)
        setAssociateList([])
        setType(outType)
        setPercent('')
        form.setFieldsValue({
            name: ''
        })
    }

    const [id, setId] = useState('')

    const edit = (item) => {
        setIsEdit(true)
        setShowModal(true)
        let temp = []
        item.id_list.forEach(i => {
            temp.push({
                value: i,
                warn: false
            })
        })
        setAssociateList(temp)
        setType(item.transactionType)
        setPercent('')
        setId(item.id)
        setPercent(item.trigger)
        form.setFieldsValue({
            name: item.categoryName
        })
    }

    /* 删除 */
    const [isModalVisible, setIsModalVisible] = useState(false);

    const [deleteId, setDeleteId] = useState('');

    const [deleting, setDeleting] = useState(false)

    const showDeleteModal = (id) => {
        setIsModalVisible(true)
        setDeleteId(id)
    }

    const handleOk = () => {
        if (deleting)
            return
        setDeleting(true)
        api.deleteCategory({
            params: deleteId,
            callback: (res) => {
                if (!res.data)
                    return
                if (res.data.code === 200) {
                    setIsModalVisible(false)
                    setDeleting(false)
                    getInfo()
                } else if (res.data.code === 50000 || res.data.code === 50001 || res.data.code === 50002 || res.data.code === 50003 || res.data.code === 50004 || res.data.code === 50005) {
                    history.push('/login')
                } else {
                    message.error(res.data.message)
                }
            }
        })
    }

    const handleCancel = () => {
        if (deleting)
            return
        setIsModalVisible(false)
    }

    const [associateList, setAssociateList] = useState([
    ]);

    const updateAssociateValue = (e, index) => {
        let temp = e.replace(/[\W]/g,'')
        let temp_list = JSON.parse(JSON.stringify(associateList))
        temp_list.splice(index, 1, {
            value: temp,
            warn: false
        })
        setAssociateList(temp_list)
    }

    const updateValidateAssociateValue = (e, index) => {
        let temp = e.replace(/[\W]/g,'')
        let temp_list = JSON.parse(JSON.stringify(associateList))
        temp_list.splice(index, 1, {
            value: temp,
            warn: (temp.length !== 4 && temp.length !== 5) ? true : false
        })
        setAssociateList(temp_list)
    }

    const addLine = () => {
        let temp_list = JSON.parse(JSON.stringify(associateList))
        temp_list.push({
            value: '',
            warn: false
        })
        setAssociateList(temp_list)
    }

    const deleteLine = (index) => {
        let temp_list = JSON.parse(JSON.stringify(associateList))
        temp_list.splice(index, 1)
        setAssociateList(temp_list)
    }


    return (
        <div className="category-wrap">
            <h1 className="clear">Category Management
                <div className="create-button" onClick={() => { add() }}>Create New Category </div>
            </h1>
            <div className="table">
                <div className="clear tab">
                    <div className={outType === 'OUT' ? 'tit bold' : 'tit'} onClick={() => { toggleOut('OUT', 'refresh') }}>Money Out</div>
                    <div className={outType === 'IN' ? 'tit bold' : 'tit'} onClick={() => { toggleOut('IN', 'refresh') }}>Money In</div>
                </div>
                {list.length > 0 ? <Table columns={[
                    {
                        title: 'Category name',
                        dataIndex: 'categoryName',
                    },
                    {
                        title: <div className="sort">
                            <span className="txt">
                                Add transctions of this type
                                <span onClick={() => { getList('type', 'descend') }} className={sort.field === 'type' && sort.type === 'descend' ? 'up active1' : 'up'}><CaretUpOutlined /></span>
                                <span onClick={() => { getList('type', 'ascend') }} className={sort.field === 'type' && sort.type === 'ascend' ? 'dn active1' : 'dn'}><CaretDownOutlined /></span>
                            </span>
                        </div>,
                        dataIndex: 'type',
                        render: (a, record) => {
                            return <div>{a > 999 ? (a / 1000).toFixed(1) + 'k' : a}</div>
                        }
                    },
                    {
                        title: <div className="sort">
                            <span className="txt">
                                Category automation trigger %
                                <span onClick={() => { getList('trigger', 'descend') }} className={sort.field === 'trigger' && sort.type === 'descend' ? 'up active1' : 'up'}><CaretUpOutlined /></span>
                                <span onClick={() => { getList('trigger', 'ascend') }} className={sort.field === 'trigger' && sort.type === 'ascend' ? 'dn active1' : 'dn'}><CaretDownOutlined /></span>
                            </span>
                        </div>,
                        dataIndex: 'trigger',
                        render: (a, record) => {
                            return <div>{a ? `${a}%` : 'No set'}</div>
                        }
                    },
                    {
                        title: 'Associate basiq category ID',
                        dataIndex: 'showIdString',
                    },
                    {
                        title: '',
                        dataIndex: 'status',
                        width: 50,
                        render: (a, record) => {
                            return <Dropdown placement="bottomRight" overlay={<Menu className="menu"
                                items={[
                                    {
                                        label: <div onClick={() => { history.push(`/description?id=${record.id}`) }}>View description in this category</div>,
                                        key: '4'
                                    },
                                    {
                                        label: <div onClick={() => { history.push(`/payee?id=${record.id}`) }}>View payee in this category</div>,
                                        key: '0'
                                    },
                                    {
                                        label: <div onClick={() => { edit(record) }}>Edit category</div>,
                                        key: '1'
                                    },
                                    {
                                        label: <div onClick={() => showDeleteModal(record.id)} className="red-font-color">Remove category</div>,
                                        key: '2'
                                    }
                                ]}
                            />
                            } trigger={['click']}>
                                <div className="trigger-button"></div>
                            </Dropdown>
                        }
                    },
                    {
                        title: '',
                        dataIndex: '',
                        width: 80
                    }

                ]}
                    dataSource={list}
                    pagination={false} /> : ''}
                {loading ? <div className="loading-content"><LoadingOutlined /></div> :
                    list.length === 0 ? <div className="empty-content">No data</div> : ''}
                <div className="total-number">Total {list.length}</div>
            </div>

            <Modal title=" " visible={isModalVisible}
                closable={false} width={540}
                className="remove-modal"
                centered={true}
                footer={null}
                onOk={handleOk} onCancel={handleCancel}>
                <p>Remove category will not delete transaction data,<br />
                    but all transactions in this category will become<br />
                    Unsorted, are you sure you want to continue?</p>
                <div className="modal-btns">
                    <Button className="cancel-btn" onClick={() => handleCancel()}>Cancel</Button>
                    <Button className="ok-btn" onClick={() => { handleOk() }}>Ok{deleting ? <LoadingOutlined /> : ''}</Button>
                </div>
            </Modal>
            {/* 弹窗 */}
            <Drawer
                className="drawer"
                title={isEdit ? 'Edit Category' : 'Create New Category'}
                placement="right"
                width={705}
                onClose={() => closeEvent()}
                visible={showModal}
                maskClosable={false}>
                <Form
                    className="form-wrap"
                    layout="vertical"
                    form={form}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}>
                    <Form.Item label="Type">
                        <div className="clear">
                            <div className={type === 'OUT' ? 'tab-btn active' : 'tab-btn'} onClick={() => { toggle('OUT') }}>Money Out</div>
                            <div className={type === 'IN' ? 'tab-btn active' : 'tab-btn'} onClick={() => { toggle('IN') }}>Money In</div>
                        </div>
                    </Form.Item>
                    <Form.Item label="Category Name" className="height100"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: ' ',
                            },
                        ]}>
                        <TextArea placeholder=" " maxLength="30" showCount={true} />
                    </Form.Item>
                    <Form.Item label="Category automation trigger %" className="height100 mar-btm-40">
                        <input placeholder=" " className={percentWarn ? 'red-border input pad-rgt-40' : 'input pad-rgt-40'} value={percent} onChange={(e) => { updateValue(e, 'percent') }} maxLength="5" />
                        <span className="percent-symbol">%</span>
                    </Form.Item>

                    {/*<Form.Item label="Category automation trigger %" className="height100 mar-btm-40">
                        <input placeholder=" " className={percentWarn ? 'red-border input pad-rgt-40' : 'input pad-rgt-40'} value={percent} onChange={(e) => { updateValue(e, 'percent') }} maxLength="5" />
                        <span className="percent-symbol">%</span>
                    </Form.Item>*/}

                    <Form.Item label="Associate basiq category ID" className="associate-input mar-btm-40">
                        <div className="clear associate-wrap">
                            {associateList.map((item, index) => {
                                return(
                                    <div key={index} className="associate-number">
                                        <input className={item.warn ? 'red-border' : ''}
                                        value={item.value}
                                        onChange={(e) => {updateAssociateValue(e.target.value, index)}}
                                        onBlur={(e) => {updateValidateAssociateValue(e.target.value, index)}}
                                        placeholder=" "
                                        maxLength="5" />
                                        <div className="delete" onClick={() => {deleteLine(index)}}></div>
                                    </div>    
                                )
                            })}
                            <div className="associate-btn" onClick={() => {addLine()}}></div>
                        </div>
                    </Form.Item>


                    <Form.Item className="form-button">
                        {!isEdit ? <Button className="blue-btn" htmlType="submit">Create{requesting ? <LoadingOutlined /> : ''}</Button> :
                            <Button className="blue-btn" htmlType="submit">Save{requesting ? <LoadingOutlined /> : ''}</Button>}
                    </Form.Item>
                </Form>
            </Drawer>

        </div>
    )

}

export default Category