import { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom";
import { Select, Table, DatePicker, message } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import '../../../assets/css/statistics.scss'
import api from '../../../api/statistics'
import format from '../../../untils/format'

const { Option } = Select
const { RangePicker } = DatePicker

const Statistics = () => {
    const history = useHistory()

    const disabledDate = (current) => {
        // Can not select days before today and today
        return current && current > format.getDayTimes(1).start + 86400000;
    }

    const getRangeTime = (e, string) => {
        if (!e)
            getInfo()
        else {
            let time = format.formatString(string)
            getInfo(time)
        }
    }

    const [customerStatistics, setCustomerStatistics] = useState({
        dailyAverageUsers: 0,
        signupsGrowthRate: 0,
        totalAppOpenDuration: 0,
        totalNumberOfTimesAppOpened: 0,
        totalNumberOfUsersWhoOpenedTheApp: 0,
        userLogins: 0,
        userRegistrations: 0,
    })

    const [cumulativeRecordedTransactions, setCumulativeRecordedTransactions] = useState({
        numberOfBankAccountTransactions: 0,
        numberOfCreditCardTransactions: 0,
        numberOfWalletTransactions: 0
    })


    useEffect(() => {
        let params = format.getDayTimes(30)
        console.log(params)
        getInfo(params)
    }, [])

    const getInfo = (params) => {
        setList([])
        setList2([])
        setList3([])
        setLoading(true)
        setLoading2(true)
        setLoading3(true)
        setCustomerStatistics({
            dailyAverageUsers: 0,
            signupsGrowthRate: 0,
            totalAppOpenDuration: 0,
            totalNumberOfTimesAppOpened: 0,
            totalNumberOfUsersWhoOpenedTheApp: 0,
            userLogins: 0,
            userRegistrations: 0,
        })
        setCumulativeRecordedTransactions({
            numberOfBankAccountTransactions: 0,
            numberOfCreditCardTransactions: 0,
            numberOfWalletTransactions: 0
        })
        api.getStatistics({
            params: params,
            callback: (res) => {
                if (!res.data)
                    return
                    
                setLoading(false)
                setLoading2(false)
                setLoading3(false)

                if (res.data.code === 200) {
                    let cus = res.data.data.customerStatistics
                    if (cus) {
                        cus.userRegistrations = cus.userRegistrations && (cus.userRegistrations > 999) ? `${(cus.userRegistrations / 1000).toFixed(1)}k` : cus.userRegistrations
                        cus.userLogins = cus.userLogins && (cus.userLogins > 999) ? `${(cus.userLogins / 1000).toFixed(1)}k` : cus.userLogins
                        cus.totalNumberOfUsersWhoOpenedTheApp = cus.totalNumberOfUsersWhoOpenedTheApp && (cus.totalNumberOfUsersWhoOpenedTheApp > 999) ? `${(cus.totalNumberOfUsersWhoOpenedTheApp / 1000).toFixed(1)}k` : cus.totalNumberOfUsersWhoOpenedTheApp
                        cus.dailyAverageUsers = cus.dailyAverageUsers && (cus.dailyAverageUsers > 999) ? `${(cus.dailyAverageUsers / 1000).toFixed(1)}k` : cus.dailyAverageUsers
                        cus.totalNumberOfTimesAppOpened = cus.totalNumberOfTimesAppOpened && (cus.totalNumberOfTimesAppOpened > 999) ? `${(cus.totalNumberOfTimesAppOpened / 1000).toFixed(1)}k` : cus.totalNumberOfTimesAppOpened
                        setCustomerStatistics(res.data.data.customerStatistics)
                    }
                    let cum = res.data.data.cumulativeRecordedTransactions
                    if (cum) {
                        cum.numberOfBankAccountTransactions = cum.numberOfBankAccountTransactions && (cum.numberOfBankAccountTransactions > 999) ? `${(cum.numberOfBankAccountTransactions / 1000).toFixed(1)}k` : cum.numberOfBankAccountTransactions
                        cum.numberOfCreditCardTransactions = cum.numberOfCreditCardTransactions && (cum.numberOfCreditCardTransactions > 999) ? `${(cum.numberOfCreditCardTransactions / 1000).toFixed(1)}k` : cum.numberOfCreditCardTransactions
                        cum.numberOfWalletTransactions = cum.numberOfWalletTransactions && (cum.numberOfWalletTransactions > 999) ? `${(cum.numberOfWalletTransactions / 1000).toFixed(1)}k` : cum.numberOfWalletTransactions
                        setCumulativeRecordedTransactions(cum)
                    }
                    let top10Leaderboard = res.data.data.top10Leaderboard
                    /*let top10Leaderboard = {
                        merchantsLeaderboardList: [
                            {
                                name: 'John Brown',
                                amount: 32,
                                transaction: 8,
                            },
                            {
                                name: 'Aim Green',
                                amount: 5,
                                transaction: 100,
                            }                    
                        ],
                        categoryLeaderboardList: [
                            {
                                amount: 11,
                                name: 'Jolk iter',
                                transaction: 98,
                                merchantsLeaderboardList: [
                                    {
                                        amount: 1,
                                        name: 'Tgg ewtwet',
                                        transaction: 32
                                    },
                                    {
                                        amount: 2,
                                        name: 'FGF arew',
                                        transaction: 12
                                    }
                                ]
                            },
                            {
                                amount: 22,
                                name: 'Jolk iter',
                                transaction: 55,
                                merchantsLeaderboardList: [
                                    {
                                        amount: 1,
                                        name: 'Tgg ewtwet',
                                        transaction: 32
                                    },
                                    {
                                        amount: 2,
                                        name: 'FGF arew',
                                        transaction: 12
                                    }
                                ]
                            },
                            {
                                amount: 23232,
                                name: 'Jolk iter',
                                transaction: 25,
                                merchantsLeaderboardList: [
                                    {
                                        amount: 1,
                                        name: 'Tgg ewtwet',
                                        transaction: 32
                                    },
                                    {
                                        amount: 2,
                                        name: 'FGF arew',
                                        transaction: 12
                                    }
                                ]
                            }

                        ]

                    }*/
                    let merchantsLeaderboardList = top10Leaderboard['merchantsLeaderboardList']
                    if (merchantsLeaderboardList && merchantsLeaderboardList.length > 1) {
                        let lst = []
                        merchantsLeaderboardList.forEach((item, index) => {
                            lst.push({
                                key: index,
                                name: item.name,
                                Amount: toFixed(item.amount),
                                Transctions: item.transaction             
                            })
                        })
                        getList('Transctions', 'descend', lst)
                    }
                    
                    
                    let categoryLeaderboardList = top10Leaderboard['categoryLeaderboardList']
                    console.log('categoryLeaderboardList----------')
                    console.log(categoryLeaderboardList)
                    console.log(categoryLeaderboardList.length)
                    if (categoryLeaderboardList && categoryLeaderboardList.length > 1) {
                        console.log('船长')

                        let lst2 = []

                        let lst3 = []

                        categoryLeaderboardList.forEach((item, index) => {
                            lst2.push({
                                key: index,
                                name: item.name,
                                Amount: toFixed(item.amount),
                                Transctions: item.transaction,
                                innerList: item.merchantsLeaderboardList,        
                            })

                            let mer = item.merchantsLeaderboardList
                            let obj = {
                                key: index,
                                name: item.name,
                                topname: mer.length > 0 ? mer[0].name : '-',
                                Amount: mer.length > 0 ? toFixed(mer[0].amount) : '-',
                                Transctions: mer.length > 0 ? mer[0].transaction : '-'
                            }
                            let temp = JSON.parse(JSON.stringify(item.merchantsLeaderboardList))
                            if (item.merchantsLeaderboardList.length > 0)
                                temp.splice(0, 1)
                            obj.innerList = temp
                            lst3.push(obj)

                        })


                        console.log('lst2----------')
                        console.log(lst2)

                        console.log('lst3----------')
                        console.log(lst3)

                        getList2('Transctions', 'descend', lst2)

                        getList3('Transctions', 'descend', lst3)
                    } else {
                        setLoading2(false)
                        setLoading3(false)
                    }



                } else if (res.data.code === 50000 || res.data.code === 50001 || res.data.code === 50002 || res.data.code === 50003 || res.data.code === 50004 || res.data.code === 50005) {
                    history.push('/login')
                } else {
                    message.error(res.data.message)
                }
            }
        })
    }

    const toFixed = (e) => {
        let temp = JSON.stringify(e)
        let arr = temp.indexOf('.') !== -1 ? temp.split('.') : []
        if (arr.length === 0)
            return `${e}.00`
        else if (arr[1].length === 1)
            return `${e}0`
        else
            return e
    }

    // 选择下拉框事件
    const [value, setValue] = useState(30)
    const handleChange = (e) => {
        setValue(e)
        if (e === 8848) {
            return
        }
        let params = format.getDayTimes(e)
        getInfo(params)
    }

    /******************************************** 表格1的数据 start ********************************************/
    // 表格事件，捕捉箭头变化
    const onChange = (pagination, filters, sorter, extra) => {
        console.log(sorter)
        // 如果所有箭头都没点亮
        if (!sorter.order) {
            // 还原数据
            return
        } else if (sorter.field === 'Amount' && sorter.order === 'descend') {
            getList('Amount', 'descend')
        } else if (sorter.field === 'Amount' && sorter.order === 'ascend') {
            getList('Amount', 'ascend')
        } else if (sorter.field === 'Transctions' && sorter.order === 'descend') {
            getList('Transctions', 'descend')
        } else if (sorter.field === 'Transctions' && sorter.order === 'ascend') {
            getList('Transctions', 'ascend')
        }
    }

    // 获取表格数据
    const [loading, setLoading] = useState(false)

    const [list, setList] = useState([])

    const getList = (field, order, lst) => {
        setList([])
        setLoading(false)
        let temp_list = lst ? lst : list
        if (field === 'Transctions' && order === 'descend') {
            let final_list = temp_list.sort((a, b) => {
                return b.Transctions - a.Transctions
            })
            setList(final_list)
        } else if (field === 'Transctions' && order === 'ascend') {
            let final_list = temp_list.sort((a, b) => {
                return a.Transctions - b.Transctions
            })
            setList(final_list)
        } else if (field === 'Amount' && order === 'descend') {
            let final_list = temp_list.sort((a, b) => {
                return b.Amount - a.Amount
            })
            setList(final_list)
        } else if (field === 'Amount' && order === 'ascend') {
            let final_list = temp_list.sort((a, b) => {
                return a.Amount - b.Amount
            })
            setList(final_list)
        }


    }

    /******************************************** 表格1的数据 end ********************************************/
    
    /******************************************** 表格2的数据 start ********************************************/
    // 表格事件，捕捉箭头变化
    const onChange2 = (pagination, filters, sorter, extra) => {
        console.log(sorter)
        // 如果所有箭头都没点亮
        if (!sorter.order) {
            // 还原数据
            return
        } else if (sorter.field === 'Amount' && sorter.order === 'descend') {
            getList2('Amount', 'descend')
        } else if (sorter.field === 'Amount' && sorter.order === 'ascend') {
            getList2('Amount', 'ascend')
        } else if (sorter.field === 'Transctions' && sorter.order === 'descend') {
            getList2('Transctions', 'descend')
        } else if (sorter.field === 'Transctions' && sorter.order === 'ascend') {
            getList2('Transctions', 'ascend')
        }
    }

    // 获取表格数据
    const [loading2, setLoading2] = useState(false)

    const [list2, setList2] = useState([])

    const getList2 = (field, order, lst) => {
        setList2([])
        setLoading2(false)
        let temp_list = lst ? lst : list2
        if (field === 'Transctions' && order === 'descend') {
            let final_list = temp_list.sort((a, b) => {
                return b.Transctions - a.Transctions
            })
            console.log(final_list)
            setList2(final_list)
        } else if (field === 'Transctions' && order === 'ascend') {
            let final_list = temp_list.sort((a, b) => {
                return a.Transctions - b.Transctions
            })
            setList2(final_list)
        } else if (field === 'Amount' && order === 'descend') {
            let final_list = temp_list.sort((a, b) => {
                return b.Amount - a.Amount
            })
            setList2(final_list)
        } else if (field === 'Amount' && order === 'ascend') {
            let final_list = temp_list.sort((a, b) => {
                return a.Amount - b.Amount
            })
            setList2(final_list)
        }
    }

    /******************************************** 表格2的数据 end ********************************************/

    /******************************************** 表格3的数据 start ********************************************/
    // 表格事件，捕捉箭头变化
    const onChange3 = (pagination, filters, sorter, extra) => {
        console.log(sorter)
        // 如果所有箭头都没点亮
        if (!sorter.order) {
            // 调接口不传排序字段
            getList3()
        } else if (sorter.field === 'Amount' || sorter.order === 'descend') {
            getList3('Amount', 'descend')
        } else if (sorter.field === 'Amount' || sorter.order === 'ascend') {
            getList3('Amount', 'ascend')
        } else if (sorter.field === 'Transctions' || sorter.order === 'ascend') {
            getList3('Transctions', 'descend')
        } else if (sorter.field === 'Transctions' || sorter.order === 'ascend') {
            getList3('Transctions', 'ascend')
        }
    }

    // 获取表格数据
    const [loading3, setLoading3] = useState(false)

    const [list3, setList3] = useState([])

    const getList3 = (field, order, lst) => {
        setList3([])
        setLoading3(false)
        let temp_list = lst ? lst : list3
        if (field === 'Transctions' && order === 'descend') {
            let final_list = temp_list.sort((a, b) => {
                return b.Transctions - a.Transctions
            })
            setList3(final_list)
        } else if (field === 'Transctions' && order === 'ascend') {
            let final_list = temp_list.sort((a, b) => {
                return a.Transctions - b.Transctions
            })
            setList3(final_list)
        } else if (field === 'Amount' && order === 'descend') {
            let final_list = temp_list.sort((a, b) => {
                return b.Amount - a.Amount
            })
            setList3(final_list)
        } else if (field === 'Amount' && order === 'ascend') {
            let final_list = temp_list.sort((a, b) => {
                return a.Amount - b.Amount
            })
            setList3(final_list)
        }
    }

    /******************************************** 表格2的数据 end ********************************************/

    // 总条数
    /*const [total, setTotal] = useState(50)

    // 页码
    const [page, setPage] = useState(1)

    // 页码变化
    const onPageChange = (e) => {
        setPage(e)
        getList(e)
    }*/

      
    return(
    
        <div className="boxs-wrap">
            <h1 className="clear">Customer Statistics
                <div className="slt">
                    <Select value={value} onChange={(e) => {handleChange(e)}}>
                        <Option value={0}>All</Option>
                        <Option value={1}>Today</Option>
                        <Option value={7}>Last 7 days</Option>
                        <Option value={30}>Last 30 days</Option>
                        <Option value={60}>Last 60 days</Option>
                        <Option value={8848}>Custom Date</Option>
                    </Select>
                </div>
                { value === 8848 ? <div className="date-wrp">
                    <RangePicker onChange={(e, s) => {getRangeTime(e, s)}} placeholder={['dd/mm/yyyy', 'dd/mm/yyyy']} format="DD/MM/YYYY" disabledDate={disabledDate} />
                </div> : '' }
            </h1>
            <div className="cloumn clear">
                <div className="box">
                    <h2>User registrations</h2>
                    <p>{customerStatistics.userRegistrations ? customerStatistics.userRegistrations : 0}</p>
                </div>
                <div className="box">
                    <h2>Signups growth rate</h2>
                    <p><span>{customerStatistics.signupsGrowthRate ? customerStatistics.signupsGrowthRate : 0}% {customerStatistics.signupsGrowthRate > 0 ? '↑' : '↓'}</span></p>
                </div>
                <div className="box">
                    <h2>User logins</h2>
                    <p>{customerStatistics.userLogins ? customerStatistics.userLogins : 0}</p>
                </div>
            </div>
            <div className="cloumn clear">
                <div className="box">
                    <h2>Daily average users</h2>
                    <p>{customerStatistics.dailyAverageUsers ? customerStatistics.dailyAverageUsers : 0}</p>
                </div>
                <div className="box">
                    <h2>Total number of times app opened</h2>
                    <p>{customerStatistics.totalNumberOfTimesAppOpened ? customerStatistics.totalNumberOfTimesAppOpened : 0}</p>
                </div>
                <div className="box">
                    <h2>Total app open duration</h2>
                    <p>{customerStatistics.totalAppOpenDuration}min</p>
                </div>
            </div>
            <div className="cloumn-bottom clear">
                <div className="pad-rgt">
                    <div className="box">
                        <h2>Total number of users who opened the app</h2>
                        <p>{customerStatistics.totalNumberOfUsersWhoOpenedTheApp ? customerStatistics.totalNumberOfUsersWhoOpenedTheApp : 0}</p>
                    </div>
                </div>
            </div>
            <h1>Cumulative Recorded Transactions</h1>
            <div className="cloumn clear mar-btm-40">
                <div className="box">
                    <h2>Number of bank account transactions</h2>
                    <p>{cumulativeRecordedTransactions.numberOfBankAccountTransactions ? cumulativeRecordedTransactions.numberOfBankAccountTransactions : 0}</p>
                </div>
                <div className="box">
                    <h2>Number of credit card transactions</h2>
                    <p>{cumulativeRecordedTransactions.numberOfCreditCardTransactions ? cumulativeRecordedTransactions.numberOfCreditCardTransactions : 0}</p>
                </div>
                <div className="box">
                    <h2>Number of wallet transactions</h2>
                    <p>{cumulativeRecordedTransactions.numberOfWalletTransactions ? cumulativeRecordedTransactions.numberOfWalletTransactions : 0}</p>
                </div>
            </div>
            <h1>Top 10 Leaderboard</h1>

            {/* 表格1 start */}
            <div className="table">
                <h3>The merchants' users are spending their money on</h3>
                {list.length > 0 ? <Table columns={[
                    {
                        title: 'Merchants',
                        dataIndex: 'name'
                    },
                    {
                        title: 'Transctions',
                        dataIndex: 'Transctions',
                        defaultSortOrder: 'descend',
                        sorter: true,
                        render: (a) => {
                            return <div>{a > 1000 ? `${(a/1000).toFixed(1)}k` : a}</div>
                        }
                    },
                    {
                        title: 'Amount',
                        dataIndex: 'Amount',
                        sorter: true,
                        render: (a) => {
                            return <div>${a}</div>
                        }
                    },
                    ]}
                    dataSource={list} onChange={onChange}
                    pagination={false} showSorterTooltip={false} /> : ''}
                {loading ? <div className="loading-content"><LoadingOutlined /></div> : 
                list.length === 0 ? <div className="empty-content">No data</div> : ''}
            </div>
            {/* 表格1 end */}

            {/* 表格2 start */}
            <div className="table">
                <h3>Most transaction category</h3>
                {list2.length > 0 ? <Table columns={[
                    {
                        title: 'Categories',
                        dataIndex: 'name'
                    },
                    {
                        title: 'Transctions',
                        dataIndex: 'Transctions',
                        defaultSortOrder: 'descend',
                        sorter: true,
                        render: (a) => {
                            return <div>{a > 1000 ? `${(a/1000).toFixed(1)}k` : a}</div>
                        }
                    },
                    {
                        title: 'Amount',
                        dataIndex: 'Amount',
                        sorter: true,
                        render: (a) => {
                            return <div>${a}</div>
                        }
                    },
                    ]}
                    dataSource={list2} onChange={onChange2}
                pagination={false} /> : ''}
                {loading2 ? <div className="loading-content"><LoadingOutlined /></div> : 
                list2.length === 0 ? <div className="empty-content">No data</div> : ''}
            </div>
            {/* 表格2 end */}

            {/* 表格3 start */}
            <div className="table">
                <h3>The top 10 merchants' users are spending their monies on each top 10 expense categories</h3>
                {list3.length > 0 ? <Table columns={[
                    {
                        title: 'Categories',
                        dataIndex: 'name',
                    },
                    {
                        title: 'Top Merchants',
                        dataIndex: 'topname'
                    },
                    {
                        title: 'Transctions',
                        dataIndex: 'Transctions',
                        defaultSortOrder: 'descend',
                        sorter: true,
                        render: (a) => {
                            return <div>{a > 1000 ? `${(a/1000).toFixed(1)}k` : a}</div>
                        }
                    },
                    {
                        title: 'Amount',
                        dataIndex: 'Amount',
                        sorter: true,
                        render: (a) => {
                            return <div>{a !== '-' ? '$' : ''}{a}</div>
                        }
                    },
                    Table.EXPAND_COLUMN,
                    ]}
                    dataSource={list3} onChange={onChange3}
                    pagination={false}
                    expandable={{
                        expandedRowRender: record => <div className="inner-conent">
                            {record.innerList ? record.innerList.map((e, i) => {
                                return (
                                    <div className="line" key={i}>
                                        <div></div>
                                        <div>{e.name}</div>
                                        <div>{e.transaction > 999 ? ((e.transaction) / 1000).toFixed(1) + 'k' : e.transaction}</div>
                                        <div>${e.amount}</div>
                                        <div className="last"></div>
                                    </div>
                                )
                            }) : ''}
                        </div>,
                    }}
                /> : ''}
                {loading3 ? <div className="loading-content"><LoadingOutlined /></div> : 
                list3.length === 0 ? <div className="empty-content">No data</div> : ''}
            </div>
            {/* 表格3 end */}

            {/*<div className="page">
                <Pagination pageSize={10} current={page} onChange={(e) => {onPageChange(e)}} total={total} />
            </div>*/}

        </div>
    
    )

}

export default Statistics