import { useState, useEffect } from 'react'
import { useHistory, useLocation } from "react-router-dom";
import { Table, Pagination, Dropdown, Menu, Drawer, Button, message } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import '../../../assets/css/category.scss'
import api from '../../../api/category'

const Category = () => {
    const history = useHistory()

    const location = useLocation()

    useEffect(() => {
        if (!location.search || location.search.indexOf('?id=') === -1)
            return
        getList()
    }, [])  

    const getList = () => {
        setLoading(true)
        setBackupList([])
        setList([])
        api.payeeOfCategory({
            params: {
                type: 1,
                categoryId: location.search.split('?id=')[1],
                pageNum: page,
                pageSize: 200
            },
            callback: (res) => {
                setLoading(false)
                if (!res.data)
                    return
                if (res.data.code === 200) {
                    let lst = []
                    res.data.data.records.forEach((e, i) => {
                        lst.push({
                            key: i,
                            categoryName: e.categoryName,
                            name: e.companyName,
                            categoryId: e.categoryId,
                            transactionType: e.transactionType
                            // id: e.categoryId
                        })
                    })
                    setList(lst)
                    setTotal(res.data.data.total)
                } else if (res.data.code === 50000 || res.data.code === 50001 || res.data.code === 50002 || res.data.code === 50003 || res.data.code === 50004 || res.data.code === 50005) {
                    history.push('/login')
                } else {
                    message.error(res.data.message)
                }
            }
        })
    }


    /******************************************** 表格1的数据 start ********************************************/

    // 获取表格数据
    const [loading, setLoading] = useState(false)

    const [list, setList] = useState([])

    const [backupList, setBackupList] = useState([])

    const onPageChange = (e) => {
        setPage(e)
        getList(e)
    }

    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)


    /******************************************** 表格1的数据 end ********************************************/

    /* 表单弹窗 */

    const [requesting, setRequesting] = useState(false)

    const [showModal, setShowModal] = useState(false)

    // 点右上角的关闭
    const closeEvent = (e) => {
        const ev = e || window.event
        ev.stopPropagation()


        if (requesting){
            return
        }
        setId('')
        setCateId('')
        setValue('')
        setCateList('')
        setShowModal(false)
    }

    const onFinish = (e) => {
        const ev = e || window.event
        ev.stopPropagation()


        if (requesting){
            return
        }
        setRequesting(true)
        let params = {
            companyName: id,
            newCategoryId: cateId,
            oldCategoryId: oldCateId,
            type: 1
        }
        api.moveCategory({
            params: params,
            callback: (res) => {
                setRequesting(false)
                if (res.data.code === 200) {
                    closeEvent()
                    getList()
                } else if (res.data.code === 50000 || res.data.code === 50001 || res.data.code === 50002 || res.data.code === 50003 || res.data.code === 50004 || res.data.code === 50005) {
                    history.push('/login')
                } else {
                    message.error(res.data.message)
                }

            }
        })
    }
    
    const [type, setType] = useState('OUT')

    const toggle = (e, t) => {
        const ev = e || window.event
        ev.stopPropagation()

        if (type === t)
            return
        setType(t)
        setValue('')
        setCateId('value')
        getCategoryInfo(t)
        setShowList(false)
    }

    const [id, setId] = useState('')

    const [cateList, setCateList] = useState('')

    const edit = (id, old, transactionType) => {  
        setShowModal(true)
        setType(transactionType)
        setId(id)
        setOldCateId(old)
        setShowList(false)
        // 请求类别数据
        getCategoryInfo(transactionType)
    }

    const getCategoryInfo = (type) => {
        setValue('')
        setCateId('')
        setCateList([])

        api.categoryList({
            params: {type: type, sortColumn: 'categoryName', sortRule: 'ASC'},
            callback: (res) => {
                if (!res.data)
                    return
                if (res.data.code === 200) {
                    let lst = []
                    res.data.data.forEach((e, i) => {
                        lst.push({
                            key: i,
                            type: Number(e.addTransactionsOfThisType),
                            trigger: Number(e.automationTriggerAmount) * 100,
                            categoryName: e.categoryName,
                            transactionType: e.transactionType,
                            id: e.categoryId
                        })
                    })
                    setCateList(lst)
                    setValue(res.data.data[0]['categoryName'])
                    setCateId(res.data.data[0]['id'])
                } else if (res.data.code === 50000 || res.data.code === 50001 || res.data.code === 50002 || res.data.code === 50003 || res.data.code === 50004 || res.data.code === 50005) {
                    history.push('/login')
                } else {
                    message.error(res.data.message)
                }
            }
        })

    }

    const [value, setValue] = useState('')

    const [showList, setShowList] = useState(false)

    const [cateId, setCateId] = useState('')

    const [oldCateId, setOldCateId] = useState('')

    const chooseCategoty = (e, value, label) => {
        const ev = e || window.event
        ev.stopPropagation()

        setValue(label)
        setCateId(value)
        setShowList(false)
    }

    const openList = (e) => {
        const ev = e || window.event
        ev.stopPropagation()

        setShowList(showList ? false : true)
    }

    return(
        <div className="category-wrap">
            <h1 className="clear">View Payee In This Category</h1>
            <div className="table">
                {list.length > 0 ? <Table columns={[
                        {
                            title: 'Company Name',
                            dataIndex: 'name',
                        },
                        {
                            title: 'Category',
                            dataIndex: 'categoryName',
                        },
                        {
                            title: '',
                            dataIndex: 'status',
                            width: 50,
                            render: (a, record) => {
                                return <Dropdown className="list-drop" overlay={<Menu className="menu2"
                                            items={[
                                            {
                                                label: <div className="move-text" onClick={() => {edit(record.name, record.categoryId, record.transactionType)}}>Move the company to another category</div>,
                                                key: '0'
                                            }
                                            ]}
                                        />
                                        } trigger={['click']}>
                                <div className="trigger-button"></div>
                                </Dropdown>
                            }
                        },
                        {
                            title: '',
                            dataIndex: '',
                            width: 80
                         }    
    
                ]}
                dataSource={list}
                pagination={false} /> : ''}

                {loading ? <div className="loading-content"><LoadingOutlined /></div> :
                    list.length === 0 ? <div className="empty-content">No data</div> : ''}
                <div className="total-number">Total {list.length}</div>

                {total > 200 ? <div className="page">
                    <Pagination pageSize={200} current={page} onChange={(e) => {onPageChange(e)}} total={total}
                    showQuickJumper={true}
                    showSizeChanger={false} />
                </div> : ''}

            </div>
            {/* 弹窗 */}
            <Drawer
                onClick={() => {openList()}}
                className="drawer"
                title="Set Category"
                placement="right"
                width={705}
                onClose={() => closeEvent()}
                visible={showModal}
                maskClosable={false}>
                        
                {/*<div className="clear" style={{'marginBottom': '30px'}}>
                    <div className={type === 'OUT' ? 'tab-btn active' : 'tab-btn'} onClick={(e) => {toggle(e, 'OUT')}}>Money Out</div>
                    <div className={type === 'IN' ? 'tab-btn active' : 'tab-btn'} onClick={(e) => {toggle(e, 'IN')}}>Money In</div>
                </div>*/}

                <div style={{'marginBottom': '40px', 'position': 'relative'}}>
                    <div className={showList ? 'trigger-area down-arrow' : 'trigger-area'} onClick={() => (openList())}>{value}</div>
                    {showList === true && cateList.length > 0 ? <ul className="cate-list">
                        {cateList.map(item => {
                            return (
                                <li className={cateId === item.id ? 'active' : ''} key={item.id}
                                onClick={(e) => {chooseCategoty(e, item.id, item.categoryName)}}>{item.categoryName}</li>
                            )
                        })}
                    </ul> : '' }
                    {showList === true && cateList.length === 0 ? <div className="empty-text">No data{showList}</div> : ''}
                </div>

        
                <div className="form-button" style={{'width': '440px'}}>
                    <Button className="blue-btn" htmlType="submit" onClick={(e) => {onFinish(e)}}>Save{requesting ? <LoadingOutlined /> : ''}</Button>
                </div>
            </Drawer>

        </div>
    )

}

export default Category