import Login from '../components/page/login/login';
import DeleteUser from '../components/page/app/delete';
import Main from '../components/page/main/index';
import Statistics from '../components/page/statistics/statistics';
import Customer from '../components/page/customer/customer';
import Category from '../components/page/category/index';
import Payee from '../components/page/category/payee';
import Description from '../components/page/category/description';
import Version from '../components/page/version/index';

let routes = [
    {
        path: '/login',
        component: Login,
        exact: true
    },
    {
        path: '/obliterate',
        component: DeleteUser,
        exact: true
    },
    {
        path: '/',
        component: Main,
        exact: true,
        routes: [
            {
                path: '/statistics',
                component: Statistics,
                exact: true
            },
            {
                path: '/customer',
                component: Customer,
                exact: true
            },
            {
                path: '/customer',
                component: Customer,
                exact: true
            },
            {
                path: '/category',
                component: Category,
                exact: true
            },
            {
                path: '/payee',
                component: Payee,
                exact: true
            },
            {
                path: '/description',
                component: Description,
                exact: true
            },
            {
                path: '/version',
                component: Version,
                exact: true
            }

        ]
    },

]

export default routes