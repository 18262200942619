import axios from './axios';
import JSEncrypt from "jsencrypt";

const api = {
    checkApi({params, callback}) {
        axios.post(`/oa/user-portal/users-check`, params).then(res => {
            callback(res)
        }).catch(err => {
            callback(err)
        })
    },
    getKeyApi({callback}) {
        axios.get(`/oa/user-portal/get-key`).then(res => {
            callback(res)
        }).catch(err => {
            callback(err)
        })
    },
    loginApi({params, callback, key}) {
        const encrypt = new JSEncrypt();
        encrypt.setPublicKey(key);
        const encrypted = encrypt.encrypt(params.password);
        params.password = encrypted;
        axios.post(`/oa/user-portal/users-login`, params).then(res => {
            callback(res)
        }).catch(err => {
            callback(err)
        })
    },
    sendApi({params, callback}) {
        axios.post(`/oa/user-portal/users-remove/sendSmsCode`, params).then(res => {
            callback(res)
        }).catch(err => {
            callback(err)
        })
    },
    checkCodeApi({params, callback}) {
        axios.post(`/oa/user-portal/users-remove/sendSmsCode-check`, params).then(res => {
            callback(res)
        }).catch(err => {
            callback(err)
        })
    },
    deleteApi({params, callback}) {
        axios.delete(`/oa/user-portal/users-remove?code=${params.code}`).then(res => {
            callback(res)
        }).catch(err => {
            callback(err)
        })
    },
}

export default api