import { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom";
import { Table, Drawer, Input, Form, Button, message, Pagination } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import '../../../assets/css/category.scss'
import api from '../../../api/version'
import format from '../../../untils/format';

const Version = () => {

    useEffect(() => {
        getList('ios')
    }, [])

    const history = useHistory()

    // 切换列表的类型
    const [outType, setOutType] = useState('ios')

    const toggleOut = (type) => {
        setOutType(type)
        getList(type)
    }

    // 表格数据
    const [page, setPage] = useState(1)

    const [total, setTotal] = useState(0)

    const [list, setList] = useState([])

    // 获取数据
    const [loading, setLoading] = useState(false)

    const getList = (type) => {
        setList([])
        setLoading(true)
        api.versionList({
            params: {
                pageNum: page,
                pageSize: 20,
                deviceType: type === 'ios' ? 1 : 2
            },
            callback: (res) => {
                setLoading(false)
                if (!res.data)
                    return
                if (res.data.code === 200) {
                    let {records, total} = res.data.data
                    records.forEach(item => {
                        item.uploadTime = format.timestampToFormatTime(item.createTime)
                    })
                    setTotal(total)
                    setList(records)
                } else if (res.data.code === 50000 || res.data.code === 50001 || res.data.code === 50002 || res.data.code === 50003 || res.data.code === 50004 || res.data.code === 50005) {
                    history.push('/login')
                } else {
                    message.error(res.data.message)
                }
            } 
        })

    }

    // 页码变化
    const onPageChange = (e) => {
        setPage(e)
        getList(e)
    }

    
    /* 表单弹窗 */

    const [requesting, setRequesting] = useState(false)

    const [showModal, setShowModal] = useState(false)

    // 点右上角的关闭
    const closeEvent = () => {
        if (requesting){
            return
        }

        setShowModal(false)
    }

    const onFinish = (values) => {
        if (requesting){
            return
        }
        setRequesting(true)
        let params = {
            currentVersion: values.version,
            deviceType: type === 'ios' ? 1 : 2,
            minVersion: values.miniVersion,
            versionDescribed: values.dtls
        }
        api.versionAdd({
            params,
            callback: callback
        })    
    }

    const callback = (res) => {
        if (!res.data)
            return
        setRequesting(false)
        if (res.data.code === 200) {
            setShowModal(false)
            setOutType(type)
            getList(type)
        } else if (res.data.code === 50000 || res.data.code === 50001 || res.data.code === 50002 || res.data.code === 50003 || res.data.code === 50004 || res.data.code === 50005) {
            history.push('/login')
        } else {
            message.error(res.data.message)
        }

    }
    const onFinishFailed = (values) => {    
    }

    const [form] = Form.useForm();

    const { TextArea } = Input

    const [type, setType] = useState('OUT')

    const toggle = (type, operate) => {
        setType(type)
    }

    const add = () => {  
        setShowModal(true)
        setType(outType)
        form.setFieldsValue({
            version: '',
            miniVersion: '',
            dtls: ''
        })    
    }

    // 限制只能输入纯数字
    const updateValue = (e, type) => {
        let temp = e.target.value.replace(/[^\d.]/g, "")
        if (type === 'version') {
            form.setFieldsValue({
                version: temp
            })
        } else if (type === 'miniVersion') {
            form.setFieldsValue({
                miniVersion: temp
            })
        }

    }
    


    return(
        <div className="category-wrap">
            <h1 className="clear">Version Record
                <div className="create-button" onClick={() => {add()}}>Upload Version Details</div>
            </h1>
            <div className="table">
                <div className="clear tab">
                    <div className={outType === 'ios' ? 'tit bold' : 'tit'} onClick={() => {toggleOut('ios', 'refresh')}}>iOS App</div>
                    <div className={outType === 'android' ? 'tit bold' : 'tit'} onClick={() => {toggleOut('android', 'refresh')}}>Android App</div>
                </div>
                {list.length > 0 ? <Table columns={[
                    {
                       title: 'Version',
                       dataIndex: 'currentVersion',
                    },
                    {
                       title: 'Minmum Version',
                       dataIndex: 'minVersion'
                    },
                    {
                       title: 'Upload Time',
                       dataIndex: 'uploadTime'
                    },
                    {
                       title: 'Update Details',
                       dataIndex: 'versionDescribed'
                    },
                            
                ]}
                dataSource={list}
                pagination={false} /> : ''}
                {loading ? <div className="loading-content"><LoadingOutlined /></div> : 
                list.length === 0 ? <div className="empty-content">No data</div> : ''}
            </div>

            <div className="page">
                <Pagination pageSize={10} current={page} onChange={(e) => {onPageChange(e)}} total={total}
                showQuickJumper={true}
                showSizeChanger={false} />
            </div>

            {/* 弹窗 */}
            <Drawer
                    className="drawer"
                    title={'Upload Version Details'}
                    placement="right"
                    width={705}
                    onClose={() => closeEvent()}
                    visible={showModal}
                    maskClosable={false}>
                        <Form
                        className="form-wrap"
                        layout="vertical"
                        form={form}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}>
                            <Form.Item label="Mobile Device">
                                <div className="clear">
                                    <div className={type === 'ios' ? 'tab-btn active' : 'tab-btn'} onClick={() => {toggle('ios')}}>iOS</div>
                                    <div className={type === 'android' ? 'tab-btn active' : 'tab-btn'} onClick={() => {toggle('android')}}>Android</div>
                                </div>
                            </Form.Item>                         
                            <Form.Item label="Version" className="height100"
                                name="version"
                                rules={[
                                {
                                    required: true,
                                    message: ' ',
                                },
                                ]}>
                                <TextArea placeholder=" " maxLength="20"
                                onChange={(e) => {updateValue(e, 'version')}} />
                            </Form.Item>
                            <Form.Item label="Minimum Version" className="height100"
                                name="miniVersion"
                                rules={[
                                {
                                    required: true,
                                    message: ' ',
                                },
                                ]}>
                                <TextArea placeholder=" " maxLength="20"
                                onChange={(e) => {updateValue(e, 'miniVersion')}} />
                            </Form.Item>

                            <Form.Item label="Update Details" className="update-input"
                                name="dtls"
                                rules={[
                                {
                                    required: true,
                                    message: ' ',
                                },
                                ]}>
                                <TextArea placeholder=" " maxLength="1000" showCount={true} />
                            </Form.Item>

                            <Form.Item className="form-button">
                                <Button className="blue-btn" htmlType="submit">Upload{requesting ? <LoadingOutlined /> : ''}</Button>
                            </Form.Item>
                        </Form>
            </Drawer>

        </div>
    )

}

export default Version