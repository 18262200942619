import axios from './axios'
import md5 from 'js-md5'

const api = {
    signIn({params, callback}) {
        // params.password = md5(params.password)
        axios.post(`/oa/admin/login`, params).then(res => {
            callback(res)
        }).catch(err => {
            callback(err)
        })
    },
    signOut({callback}) {
        axios.post(`/oa/admin/logout`).then(res => {
            callback(res)
        }).catch(err => {
            callback(err)
        })
    },

    registerApi({params, callback}) {
        // params.password = md5(params.password)
        axios.post(`/venue-account/register`, params).then(res => {
            callback(res)
        }).catch(err => {
            callback(err)
        })
    },
    sendEmailCode({params, callback}) {
        axios.post(`/venue-account/sendEmailCode`, params).then(res => {
            callback(res)
        }).catch(err => {
            callback(err)
        })
    },
    checkEmailCode({params, callback}) {
        axios.post(`/venue-account/checkEmailCode`, params).then(res => {
            callback(res)
        }).catch(err => {
            callback(err)
        })
    },
    forgetPassword({params, callback}) {
        // params.password = md5(params.password)
        axios.put(`/venue-account/forgetPassword`, params).then(res => {
            callback(res)
        }).catch(err => {
            callback(err)
        })
    },
}

export default api