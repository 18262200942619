import { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom";
import { Table, Pagination, Input, message, Button, Modal } from 'antd'
import { LoadingOutlined, CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons'
import '../../../assets/css/customer.scss'
import api from '../../../api/customer'
import format from '../../../untils/format'

const Customer = () => {
    const history = useHistory()

    useEffect(() => {
        getList('date', 'ascend', 0)
    }, [])

    // 获取表格数据
    const [loading, setLoading] = useState(false)

    const [page, setPage] = useState(1)

    const [total, setTotal] = useState(0)
    
    const getInfo = (cur, p, keyword, field, order) => {
        if (cur === 0) {
            setLoading(true)
            setList([])
            setTotal(0)
        } else {
            setLoading2(true)
            setList2([])
            setTotal(0)
        }
        let params = {
            pageNum: p ? p : page,
            pageSize: 200
        }
        if (field === 'date')
            params.sortColumn = 'createTime'
        if (field === 'birth')
            params.sortColumn = 'yearOrBirth'
        if (field === 'bank')
            params.sortColumn = 'numberOfBankAccountTransactions'
        if (field === 'credit')
            params.sortColumn = 'numberOfCreditCardTransactions'
        if (field === 'wallet')
            params.sortColumn = 'numberOfWalletTransactions'
        if (field === 'times')
            params.sortColumn = 'totalAppOpenTimes'
        if (field === 'duration')
            params.sortColumn = 'totalAppOpenDuration'
        if (order === 'descend')
            params.sortRule = 'DESC'
        if (order === 'ascend')
            params.sortRule = 'ASC'

        if ((!field || !order) && current === 0) {
            params.sortColumn = 'createTime'
            params.sortRule = 'DESC'
        }
        if ((!field || !order) && current === 1) {
            params.sortColumn = 'numberOfBankAccountTransactions'
            params.sortRule = 'DESC'
        }
        if (keyword)
            params.keyword = keyword
        api.getUserList({
            params,
            callback: (res) => {
                setLoading(false)
                setLoading2(false)
                if (!res.data)
                    return
                if (res.data.code === 200) {
                    let temp = res.data.data.total
                    setTotal(typeof(temp) === 'string' ? Number(temp) : temp)
                    let records = res.data.data.records
                    /*let records = [
                        {
                            areaCode: '+61',
                            avatarUrl: 'https://i.pinimg.com/originals/9a/be/5f/9abe5f0ad84b083a1a52dac183c7bc89.jpg',
                            birthday: '2000-11-02',
                            createTime: 1654876800,
                            disabled: 0,
                            fullName: 'B uu',
                            numberOfBankAccountTransactions: 1,
                            numberOfCreditCardTransactions: 2,	
                            numberOfWalletTransactions: 90,
                            totalAppOpenDuration: 23,	
                            totalAppOpenTimes: 54,
                            userEmail: 'hello@gmail.com',
                            userId: 21,
                            userMobile: '412345678'
                        },
                        {
                            areaCode: '+61',
                            avatarUrl: 'https://i.pinimg.com/originals/46/28/27/462827d5ff7888e7319581becc8449d1.jpg',
                            birthday: '1996-11-02',
                            createTime: 1654976800,
                            disabled: 0,
                            fullName: 'H ii',
                            numberOfBankAccountTransactions: 32,
                            numberOfCreditCardTransactions: 23,	
                            numberOfWalletTransactions: 0,
                            totalAppOpenDuration: 2,	
                            totalAppOpenTimes: 5,
                            userEmail: 'world@gmail.com',
                            userId: 11,
                            userMobile: '487654321'
                        }
                    ]*/
                    if (records.length === 0)
                        return
                    let lst1 = []
                    let lst2 = []
                    records.forEach((item, indx) => {
                        lst1.push({
                            key: indx,
                            customer: item.fullName,
                            email: item.userEmail,
                            birth: item.birthday ? format.dateStringTimestamp(item.birthday) : '',
                            // birthFormat: item.birthday ? format.dateStringFormat(item.birthday) : '',
                            birthFormat: item.yearOrBirth,
                            url: item.avatarUrl,
                            id: item.userId,
                            mobile: item.userMobile ? format.formatNumber(`${item.areaCode}${item.userMobile}`) : item.areaCode,
                            date: typeof(item.createTime) === 'string' ? Number(item.createTime) : item.createTime,
                            register: format.timestampToDate(item.createTime),
                            disabled: item.disabled
                        })
                        lst2.push({
                            key: indx,
                            customer: item.fullName,
                            url: item.avatarUrl,
                            id: item.userId,
                            bank: item.numberOfBankAccountTransactions,
                            credit: item.numberOfCreditCardTransactions,
                            wallet: item.numberOfWalletTransactions,
                            times: item.totalAppOpenTimes,
                            duration: item.totalAppOpenDuration,
                            mobile: item.userMobile ? format.formatNumber(`${item.areaCode}${item.userMobile}`) : item.areaCode,
                            date: typeof(item.createTime) === 'string' ? Number(item.createTime) : item.createTime,
                            register: format.timestampToDate(item.createTime),
                            disabled: item.disabled
                        })
                    })
                    if (cur === 0) {
                        console.log('8888888888888888')
                        setList(lst1)
                    } else {
                        setList2(lst2)
                        console.log('999999999999999999999999999')
                    }
                    // setList(lst1)
                    // getList('date', 'descend', lst1)
                    // getList2('bank', 'descend', lst2)
                    // setBackupList(lst1)
                    // setBackupList2(lst2)
                } else if (res.data.code === 50000 || res.data.code === 50001 || res.data.code === 50002 || res.data.code === 50003 || res.data.code === 50004 || res.data.code === 50005) {
                    history.push('/login')
                } else {
                    message.error(res.data.message)
                }
            }
        })
    }


    const [value, setValue] = useState('')

    const [timer, setTimer] = useState(null)

    // 输入框的事件
    const updateValue = (e) => {
        setValue(e)
        if (timer) {
            clearTimeout(timer)
        }
        const temp = setTimeout(() => {
            if (current === 0)
                getInfo(current, 1, e, sort1.field, sort1.type)
            else
                getInfo(current, 1, e, sort2.field, sort2.type)
        }, 2000)
        setTimer(temp)
    }

    /******************************************** 表格1的数据 start ********************************************/

    const [list, setList] = useState([])

    const getList = (field, order, cur) => {
        setSort1({
            field: field,
            type: order
        })
        getInfo(cur ? cur : current, page, value, field, order)
    }
    const onPageChange = (e) => {
        setPage(e)
        if (current === 0)
            getInfo(current, e, value, sort1.field, sort1.type)
        else
            getInfo(current, e, value, sort2.field, sort2.type)
    }

    const switchStatus = (item) => {
        console.log(item)
        if (!item.disabled){
            // 弹出弹窗
            showModal(true)
            setId(item.id)
            return
        }
        let temp_list = JSON.parse(JSON.stringify(list))
        let item_indx
        let temp_item
        temp_list.forEach((e, i) => {
            if (e.id === item.id) {
                temp_item = e
                item_indx = i
                return
            }
        })

        api.userDisable({
            params: {
                userId: temp_item.id,
                isDisable: temp_item.disabled ? 0 : 1  
            },
            callback: (res) => {
                if (!res.data)
                    return
                if (res.data.code === 200) {
                    temp_item.disabled = temp_item.disabled ? false : true
                    temp_list.splice(item_indx, 1, temp_item)
                    setList(temp_list)            
                } else if (res.data.code === 50000 || res.data.code === 50001 || res.data.code === 50002 || res.data.code === 50003 || res.data.code === 50004 || res.data.code === 50005) {
                    history.push('/login')
                } else {
                    message.error(res.data.message)
                }
            } 
        })
    }
    
    // 当前排序
    const [sort1, setSort1] = useState({
        field: 'date',
        type: 'ascend'
    })

    /******************************************** 表格1的数据 end ********************************************/

    /******************************************** 表格2的数据 start ********************************************/

    // 获取表格数据
    const [loading2, setLoading2] = useState(false)

    const [list2, setList2] = useState([])

    const getList2 = (field, order, cur) => {
        setSort2({
            field: field,
            type: order
        })
        getInfo(cur ? cur : current, page, value, field, order)
    }
    // 当前排序
    const [sort2, setSort2] = useState({
        field: 'bank',
        type: 'ascend'
    })
    
    /******************************************** 表格2的数据 end ********************************************/
    const [current, setCurrent] = useState(0)

    const switchTable = () => {
        if (loading || loading2)
            return
        let final = current === 0 ? 1 : 0
        setCurrent(final)
        if (final === 0) {
            getList(sort1.field, sort1.type, final)
        } else {
            getList2(sort2.field, sort2.type, final)
        }
    }
    
        /* 禁用 */
        const [isModalVisible, setIsModalVisible] = useState(false);

        const [id, setId] = useState('');
    
        const [requesting, setRequesting] = useState(false)
    
        const showModal = (id) => {
            setIsModalVisible(true)
            setId(id)
        }
    
        const handleOk = () => {
            if (requesting)
                return
                setRequesting(true)
                api.userDisable({
                    params: {
                        userId: id,
                        isDisable: 1  
                    },
                    callback: (res) => {
                        setIsModalVisible(false)
                        setRequesting(false)
                        if (!res.data)
                            return
                        if (res.data.code === 200) {
                            getList(sort1.field, sort1.type, 0)
                        } else if (res.data.code === 50000 || res.data.code === 50001 || res.data.code === 50002 || res.data.code === 50003 || res.data.code === 50004 || res.data.code === 50005) {
                            history.push('/login')
                        } else {
                            message.error(res.data.message)
                        }
                    } 
                })
        }
    
        const handleCancel = () => {
            if (requesting)
                return
            setIsModalVisible(false)
        }
    
      
    return(
        <div className="cos-wrap">

            <Modal title=" " visible={isModalVisible}
            closable={false} width={540}
            className="remove-modal"
            centered={true}
            footer={null}
            top="100"
            onOk={handleOk} onCancel={handleCancel}>
                <p>After disabling this account will no longer<br />
                be able to log in. Are you sure you want to<br />
                continue?</p>
                <div className="modal-btns">
                    <Button className="cancel-btn" onClick={() => handleCancel()}>Cancel</Button>
                    <Button className="ok-btn" onClick={() => {handleOk()}}>Suspension{requesting ? <LoadingOutlined /> : ''}</Button>
                </div>
            </Modal>

                <h1 className="clear">Customer Management
                    <div className="switch-box" onClick={() => switchTable()}>Switch to track view</div>
                    <div className="search-box">
                        <Input type="text" maxLength="50" placeholder="Search" value={value} onChange={(e) => {updateValue(e.target.value)}} />
                    </div>
                </h1>
                {/* 表格1 start */}
                {current === 0 ? <div className="table">
                    {list.length > 0 ? <><Table columns={[
                        {
                            title: 'Customer',
                            dataIndex: 'customer',
                            render: (a, record) => {
                                return <div className="avatar-info">
                                    <div className="avatar" style={{'backgroundImage': `url(${record.url})`}}></div>
                                    <h2>{record.customer}</h2><p>{record.mobile}</p>
                                </div>
                            }
                        },
                        {
                            title: 'Email',
                            dataIndex: 'email'
                        },
                        {
                            title: <div className="sort">
                            <span className="txt">
                                Year of Birth
                            <span onClick={() => {getList('birth', 'descend')}} className={sort1.field === 'birth' && sort1.type === 'descend' ? 'up active1' : 'up'}><CaretUpOutlined /></span>
                            <span onClick={() => {getList('birth', 'ascend')}} className={sort1.field === 'birth' && sort1.type === 'ascend' ? 'dn active1' : 'dn'}><CaretDownOutlined /></span>
                            </span>
                            </div>,
                            render: (a, record) => {
                                    return <div>{record.birthFormat}</div>
                                }
                            },
                        {
                            title: <div className="sort">
                                <span className="txt">
                                    Registration Date
                                <span onClick={() => {getList('date', 'descend')}} className={sort1.field === 'date' && sort1.type === 'descend' ? 'up active1' : 'up'}><CaretUpOutlined /></span>
                                <span onClick={() => {getList('date', 'ascend')}} className={sort1.field === 'date' && sort1.type === 'ascend' ? 'dn active1' : 'dn'}><CaretDownOutlined /></span>
                                </span>
                            </div>,
                            dataIndex: 'date',
                            render: (a, record) => {
                                return <div>{record.register}</div>
                            }
                        },
                        {
                            title: 'Status',
                            dataIndex: 'disabled',
                            width: 150,
                            render: (disabled, record) => {
                                return <div onClick={() => {switchStatus(record)}} className={disabled ? 'swith-btn' : 'swith-btn active'}></div>
                            }
                        }
                    ]}
                    dataSource={list}
                    pagination={false} /><div className="total-number">Total {total}</div></> : ''}
                    {loading ? <div className="loading-content"><LoadingOutlined /></div> : 
                    list.length === 0 ? <div className="empty-content">No data</div> : ''}
                </div> : ''}

                {/* 表格2 start */}
                {current === 1 ? <div className="table table2">
                    {list2.length > 0 ? <><Table columns={[
                        {
                            title: 'Customer',
                            dataIndex: 'customer',
                            render: (a, record) => {
                                return <div className="avatar-info">
                                    <div className="avatar" style={{'backgroundImage': `url(${record.url})`}}></div>
                                    <h2>{record.customer}</h2>
                                    <p>{record.mobile}</p>
                                </div>
                            }
                        },
                        {
                            title: <div className="sort">
                            <span className="txt">
                                Number of bank<br />account transactions
                            <span style={{'top':'1px'}} onClick={() => {getList2('bank', 'descend')}} className={sort2.field === 'bank' && sort2.type === 'descend' ? 'up active1' : 'up'}><CaretUpOutlined /></span>
                            <span style={{'top':'9px'}} onClick={() => {getList2('bank', 'ascend')}} className={sort2.field === 'bank' && sort2.type === 'ascend' ? 'dn active1' : 'dn'}><CaretDownOutlined /></span>
                            </span>
                            </div>,
                            dataIndex: 'bank'
                        },
                        {
                            title: <div className="sort">
                            <span className="txt">
                                Number of credit<br />card transactions
                            <span style={{'top':'1px'}} onClick={() => {getList2('credit', 'descend')}} className={sort2.field === 'credit' && sort2.type === 'descend' ? 'up active1' : 'up'}><CaretUpOutlined /></span>
                            <span style={{'top':'9px'}} onClick={() => {getList2('credit', 'ascend')}} className={sort2.field === 'credit' && sort2.type === 'ascend' ? 'dn active1' : 'dn'}><CaretDownOutlined /></span>
                            </span>
                            </div>,
                            dataIndex: 'credit'
                        },
                        {
                            title: <div className="sort">
                            <span className="txt">
                                Number of wallet<br />transactions
                            <span style={{'top':'1px','right':'-50px'}} onClick={() => {getList2('wallet', 'descend')}} className={sort2.field === 'wallet' && sort2.type === 'descend' ? 'up active1' : 'up'}><CaretUpOutlined /></span>
                            <span style={{'top':'9px','right':'-50px'}} onClick={() => {getList2('wallet', 'ascend')}} className={sort2.field === 'wallet' && sort2.type === 'ascend' ? 'dn active1' : 'dn'}><CaretDownOutlined /></span>
                            </span>
                            </div>,
                            dataIndex: 'wallet'
                        },
                        {
                            title: <div className="sort">
                            <span className="txt">
                                Total App Open<br />times
                            <span style={{'top':'1px','right':'-80px'}} onClick={() => {getList2('times', 'descend')}} className={sort2.field === 'times' && sort2.type === 'descend' ? 'up active1' : 'up'}><CaretUpOutlined /></span>
                            <span style={{'top':'9px','right':'-80px'}} onClick={() => {getList2('times', 'ascend')}} className={sort2.field === 'times' && sort2.type === 'ascend' ? 'dn active1' : 'dn'}><CaretDownOutlined /></span>
                            </span>
                            </div>,
                            dataIndex: 'times'
                        },
                        {
                            title: <div className="sort">
                            <span className="txt">
                                Total app open<br />duration
                            <span style={{'top':'1px','right':'-55px'}} onClick={() => {getList2('duration', 'descend')}} className={sort2.field === 'duration' && sort2.type === 'descend' ? 'up active1' : 'up'}><CaretUpOutlined /></span>
                            <span style={{'top':'9px','right':'-55px'}} onClick={() => {getList2('duration', 'ascend')}} className={sort2.field === 'duration' && sort2.type === 'ascend' ? 'dn active1' : 'dn'}><CaretDownOutlined /></span>
                            </span>
                            </div>,
                            dataIndex: 'duration',
                            width: 150
                        }
                    ]}
                        dataSource={list2}
                    pagination={false} /><div className="total-number">Total {total}</div></> : ''}
                    {loading2 ? <div className="loading-content"><LoadingOutlined /></div> : 
                    list2.length === 0 ? <div className="empty-content">No data</div> : ''}
                </div> : ''}

                <div className="page">
                    <Pagination pageSize={200} current={page} onChange={(e) => {onPageChange(e)}} total={total}
                    showQuickJumper={true}
                    showSizeChanger={false} />
                </div>



        </div>
    )

}

export default Customer