import { useState, useEffect }from 'react';
import { message, Form, Input, Button } from 'antd';
import { useHistory } from "react-router-dom";
import { LoadingOutlined } from '@ant-design/icons';
import api from '../../../api/login';
import '../../../assets/css/login.scss';

const Login = (props) => {
    const history = useHistory()

    const [formRef] = Form.useForm();

    const [loading, setLoading] = useState(false);

    const [username, setUsername] = useState('');


    function onFinish (values) {
        if (username.length < 1)
            return
        sign()
    }

    function onFinishFailed (errorInfo) {
        // 
    }


    function sign () {
        setLoading(true)

        api.signIn({
            params: {
                userName: username,
                password: formRef.getFieldsValue()['password']
            },
            callback: callback
        })
    }


    function callback (res) {
        setLoading(false)
        if (!res.data){
            return
        }
        if (res.data.code === 200) {
            localStorage.setItem('token', res.headers['moneyvaseoa-satoken'])
            history.push('/statistics')
        } else {
            message.error(res.data.message)
        }
    }

    const formatUsername = (value) => {
        // let temp = value.replace(/[^\d]/g,'')
        let temp = value.replace(/\s+/g,'')
        setUsername(temp)
    }


    return(
        <div className="bdg">
                <div className="wrap">
                    <div className="logo"></div>
                    <div className="cont">
                        <h1>Welcome back!</h1>
                        <Form
                        className="login-form"
                        layout="vertical"
                        form={formRef}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        >
                            <Form.Item label="Username"
                                rules={[
                                {
                                    required: true,
                                    message: ' ',
                                },
                                ]}>
                            
                                <Input maxLength="20" placeholder=" " value={username} onChange={(e) => {formatUsername(e.target.value)}} />
                            </Form.Item>
                            <Form.Item label="Password"
                                name="password"
                                rules={[
                                {
                                    required: true,
                                    message: ' ',
                                },
                                ]}>
                                <div className="psd">
                                    <Input placeholder=" " maxLength="16" type="password" />
                                </div>
                            </Form.Item>
                            <Form.Item>
                                <Button className="btn-login" htmlType="submit">Login {loading ? <LoadingOutlined /> : ''}</Button>
                            </Form.Item>
                        </Form>
                    </div>
            </div>

        </div>
    )

}

export default Login